import request from '@/utils/request'

export function publicList (PageIndex=1, PageSize=10000) {
  return request({
    url: 'api/app/fileLibCategory/publicList',
    method: 'GET',
    data: { PageIndex, PageSize, Sort: 'sortId ASC'},
  })
}

// 获取列表
export function fileLib(PageIndex, PageSize, info) {
  return request({
    url: `/api/app/fileLib/publicList`,
    method: 'GET',
    data: { PageIndex, PageSize, Sort: 'sortId ASC', ...info }
  })
}
