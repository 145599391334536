<template>
  <div class="detail-container" id="courseDetail">
    <ReturnBtn @getReturnUrl="onRetrun" />
    <div class="container-contents">
      <div class="container-content"></div>
      <div class="content-user">
        <img src="../../assets/images/user.png" alt />
      </div>
      <div class="content-name">{{ info.studentDto?.name }}</div>
      <div class="content-time">
        <span> 来源于：{{ info.className }}</span>
      </div>
      <div class="content-header">
        <div class="content-title">
          <div class="title-left"></div>
          <span>赛事作品</span>
          <div class="title-right"></div>
        </div>
      </div>

      <div
        class="content-video"
        id="iframe2"
        :style="{ transform: 'scale(' + scale + ')' }"
      >
        <iframe
          v-show="load"
          :src="src"
          frameborder="0"
          style="width: 483px; height: 407px"
          ref="iframe"
          allow="microphone;camera;midi;encrypted-media;"
        ></iframe>
        <div class="load" v-show="!load">
          <img src="../../assets/images/loading.gif" alt style="width: 100%" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { pythonUrl, scratchUrl } from "@/settings";
import { detailInfo as info } from "@/api/match";
import ReturnBtn from "@/components/ReturnBtn";
export default {
  components: { ReturnBtn },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      scale: (window.innerWidth / 1920) * 1.5,
      iframe: null,
      load: false,
      src: "",
      info: {},
    });

    const id = route.query.id;
    onMounted(() => {
      state.iframe.allow = "microphone;camera;midi;encrypted-media;";
      state.iframe.onload = () => (state.load = true);

    });
    info(id).then((res) => {
      state.info = res;
      const url = state.info.type == 1 ? pythonUrl : scratchUrl;
      state.src = `${url}player.html?sb3=${encodeURIComponent(
        state.info.answerOption
      )}`;
    });
    state.onRetrun = () => router.push('/match?id'+ route.query.matchId);
    return state;
  },
};
</script>

<style lang="less" scoped>
.detail-container {
  background: url("../../assets/images/courses/index-bg.png") no-repeat;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  position: fixed;
  overflow-y: scroll;

  .container-contents {
    position: absolute;
    left: 358px;
    top: 124px;
    width: 1200px;
    min-height: 745px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 30px;
    margin-bottom: 198px;
    padding-bottom: 58px;
    .container-content {
      width: 1200px;
      height: 745px;
      border-radius: 30px;
    }
    .content-user {
      position: absolute;
      left: 41px;
      top: 18px;
      width: 120px;
      height: 114px;
      background: linear-gradient(
        230deg,
        rgba(113, 160, 195, 1),
        rgba(255, 255, 255, 1),
        rgba(178, 204, 222, 1)
      );
      border-radius: 50%;
      text-align: center;
      padding-top: 7px;
      padding-left: 2px;
      img {
        width: 103px;
        height: 98px;
        border-radius: 50%;
      }
    }
    .content-name {
      position: absolute;
      left: 190px;
      top: 35px;
      font-size: 31px;
      font-family: bold;
      color: rgba(255, 1255, 255, 1);
      -webkit-text-stroke: 1px #103ecf;
    }
    .content-time {
      position: absolute;
      left: 190px;
      top: 90px;
      font-size: 18px;
      font-family: " Microsoft YaHei";
      color: rgba(153, 153, 153, 1);
    }
    .content-header {
      position: absolute;
      right: 39px;
      top: 50px;
      width: 450px;
      display: flex;
      justify-content: center;
      .content-title {
        height: 77px;
        border: 3px solid rgba(255, 184, 0, 1);
        border-radius: 10px;
        text-align: center;
        font-size: 28px;
        font-family: bold;
        color: rgba(255, 184, 0, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        .title-left {
          width: 18px;
          height: 18px;
          background: rgba(255, 184, 0, 1);
          border-radius: 50%;
          margin-left: 15px;
        }
        span {
          margin: 0 8px;
          padding-bottom: 5px;
        }
        .title-right {
          width: 18px;
          height: 18px;
          background: rgba(255, 184, 0, 1);
          border-radius: 50%;
          margin-right: 15px;
        }
      }
    }
    .content-works {
      position: absolute;
      left: 682px;
      top: 216px;

      .works-content {
        display: flex;
        align-items: center;
        img {
          width: 21px;
          height: 21px;
        }
        span {
          font-size: 24px;
          font-family: "Microsoft YaHei";
          color: rgba(75, 81, 234, 1);
          margin-left: 5px;
          padding-bottom: 2px;
        }
      }
      .works-all {
        width: 450px;
        max-height: 115px;
        padding-top: 10px;
        padding-left: 25px;
        overflow: hidden;
        span {
          font-size: 20px;
          font-family: "Microsoft YaHei";
          color: rgba(51, 51, 51, 1);
        }
      }
      .share {
        user-select: none;
        // margin-left: 50%;

        cursor: pointer;
        position: absolute;
        right: -30%;
        transform: translateX(-50%);
        top: 390px;
        width: 229.6px;
        padding: 20px;
        background: #4950eb;
        border-radius: 10px;
        font-size: 24px;
        text-align: center;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        img {
          vertical-align: middle;
          width: 25px;
        }
      }
      .edit {
        user-select: none;
        // margin-left: 50%;
        img {
          vertical-align: middle;
          width: 25px;
        }
        cursor: pointer;
        position: absolute;
        right: 25%;
        transform: translateX(-50%);
        top: 390px;
        width: 229.6px;
        padding: 20px;
        background: #ecc03b;
        border-radius: 10px;
        font-size: 24px;
        text-align: center;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
      }
      .content1 {
        margin-top: 26px;
      }
    }
    #iframe2 {
      transform-origin: 0 0 0;
      width: 483px;
      height: 470px;
      .load {
        border: 1px solid #00ccff;
        background: white;
        position: absolute;
        width: 483px;
        height: 400px;
        img {
          position: absolute;
          left: 50%;
          top: 50%;
          max-width: 80%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
    .content-video {
      position: absolute;
      left: 172px;
      top: 166px;

      video {
        width: 618px;
        height: 389px;
      }
    }

    .comment {
      width: 96%;
      min-height: 135px;
      background-color: #fff;
      margin-left: 2%;
      padding-bottom: 30px;
      .comment-textarea {
        display: flex;
        padding-top: 25px;
        img {
          width: 52px;
          height: 52px;
          border-radius: 50%;
          margin-left: 50px;
        }
        .textarea-submit {
          width: 85%;
          height: 150px;
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          textarea {
            width: 100%;
            height: 85px;
            background: rgba(247, 247, 247, 1);
            border: 1px solid rgba(204, 204, 204, 1);
            border-radius: 10px;
            padding: 10px 0 10px 10px;
            font-size: 14px;
          }
          textarea::-webkit-scrollbar {
            display: none;
          }
          textarea::-webkit-input-placeholder {
            // font-size:85px);
            color: #ccc;
          }
          .submit {
            width: 85px;
            height: 30px;
            background-color: #0033ff;
            color: #fff;
            text-align: center;
            line-height: 30px;
            font-size: 16px;
            border-radius: 15px;
            margin-top: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>