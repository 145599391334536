const getters = {
    token: state => state.user.token,
    isTenants: state => state.user.isTenants,
    tenantsApi: state => state.user.tenantInfo.tenantsApi,
    tenantInfo: state => state.user.tenantInfo,
    isInitTenant: state => state.user.isInitTenant,
    curDate: state => state.user.curDate,
    curTheme: state => state.user.curTheme,
    curThemeImg: state => state.user.curThemeImg,
    studentVersion: state => state.user.studentVersion,
}
export default getters
