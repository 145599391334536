import defaultTheme from './style.less'
export default [
    defaultTheme,
    {
        baseColor: '#fff',
        navBgColor: 'rgba(51,103,214)',
        // navBgColor: '#36f',
        footColor: '#4d4d4d',
        // fontColor: 'red',
        // navActiveColor: '#fff', // 下划线变#06f
        footerBorder: '#e2dbd6',
        footerText: '#fff',
        indexBg: '#4d4d4d',
        indexNavColor: '#fff',
        indexActiveColor: '#fff',
        //indexNavFollowParentBgColor: 'red',
        indexNavFollowParentTextColor: '#000',
        indexNavFollowParentBgColor: '#fff',
        // indexNavCreateBgColor: @indexNavCreateBgColor,
        indexNavCreateItemBorderColor: '#d86369',
        // indexNavCreateItemTextColor: @indexNavCreateItemTextColor,
        indexNavCreateItemHoverColor: '#d86369',
        // indexAdTopBg: 'transparent',
        // indexAdTopTextColor: '#000',
        // indexAdTopImgBg: '#06f',
        // indexAdBg: '#fff',
        // indexAdTextColor: '#000',
        // indexTextRadioColor: '#06f',
        // indexTextBorderColor: '#06f',
        // indexArticleTopColor: 'transparent',
        // indexArticleTopTextColor: '#000',
        // indexArticleTopIconColor: '#06f',
        // indexArticleBgColor: '#fff',
        // indexArticleTextColor: '#000',
        // indexResultTabbarLeftBorderColor: '#8590a6',
        // indexResultTabbarLeftCircleColor: '#8590a6',
        indexResultTabbarLeftTextColor: '#36f',
        // indexResultTabbarLeftBgColorOfActive: '#06f',
        // indexResultTabbarLeftCircleColorOfActive: '#fff',
        // indexResultTabbarLeftTextColorOfActive: '#fff',
        // indexResultTabbarRightBorderColor: '#8590a6',
        // indexResultTabbarRightCircleColor: '#8590a6',
        indexResultTabbarRightTextColor: '#ecc03b',
        // indexResultTabbarRightBgColorOfActive: '#06f',
        // indexResultTabbarRightCircleColorOfActive: '#fff',
        // indexResultTabbarRightTextColorOfActive: '#fff',



        indexResultTabbarSearchTitleBgColor: '#06f',
        // indexResultTabbarSearchTitleTextColor: @indexResultTabbarSearchTitleTextColor,
        indexResultTabbarSearchContentBgColor: '#b2cef7',
        indexResultTabbarSearchContentTextColor: '#000',
        indexResultTabbarSearchContentTipsColor: '#fff',
        // indexResultTabbarSearchFilterBgColor: @indexResultTabbarSearchFilterBgColor,
        // indexResultTabbarSearchFilterTextColor: @indexResultTabbarSearchFilterTextColor,
        // indexResultTabbarSearchFilterBorderColor: @indexResultTabbarSearchFilterBorderColor,
        // indexResultTabbarSearchFilterTextColorByActive: @indexResultTabbarSearchFilterTextColorByActive,
        indexResulItemBgColor: 'rgba(246,247,250)',
        // rgba (252, 237, 218)
        indexResulItemBorderColor: '#dfdfdf',
        indexResultItemInfoTextColor: '#000',
        // indexResultItemInfoUserImgBgColor: @indexResultItemInfoUserImgBgColor,
        indexResultItemInfoUserTextColor: '#001133',
        indexResultItemInfoSeeNumTextColor: '#001133',
        indexResultBtnMainColor: '#06f',
        indexResultBtnTextColor: '#000',
        // detailsInfoBgColor: @detailsInfoBgColor,
        // detailsInfoUserImgBgColor: @detailsInfoUserImgBgColor,
        detailsInfoUserTextColor: '#063c82',
        //todo 漏头像
        // detailsInfoDescTextColor: @detailsInfoDescTextColor,
        // detailsInfoTypeBorderColor: '#06f',
        // detailsInfoTypeTextColor: '#06f',
        // detailsInfoTypeCircleColor: '#06f',
        // detailsInfoDetailsLabelTextColor: '#8590a6',
        // detailsInfoDetailsTextColor: @detailsInfoDetailsTextColor,
        // detailsInfoBtnBgColor: 'rgb(88, 104, 209)',
        // detailsInfoBtnTextColor: @detailsInfoBtnTextColor,
        // detailsInfoBtn2BgColor: 'rgb(15, 136, 235)',
        // detailsInfoBtn2TextColor: @detailsInfoBtn2TextColor,
        // detailsInfoPreviewBgColor: @detailsInfoPreviewBgColor,
        // detailsInfoPreviewBorderColor: @detailsInfoPreviewBorderColor,
        // detailsInfoCommentBgColor: @detailsInfoCommentBgColor,
        // detailsInfoCommentTextareaBgColor: @detailsInfoCommentTextareaBgColor,
        // detailsInfoCommentTextareaBorderColor: @detailsInfoCommentTextareaBorderColor,
        detailsInfoCommentBtnColor: '#06f',
        // detailsInfoCommentTextColor: @detailsInfoCommentTextColor,
        // detailsInfoCommentUserNameTextColor: @detailsInfoCommentUserNameTextColor,
        // detailsInfoCommentContentTextColor: @detailsInfoCommentContentTextColor,
        // detailsInfoCommentNullTextColor: @detailsInfoCommentNullTextColor,
        // detailsInfoCommentTimeTextColor: @detailsInfoCommentTimeTextColor,
        // downloadTextColor: @downloadTextColor,

        indexMathItemBgColor: 'rgba(246,247,250)',
        indexMathItemBorder: '1px solid #dfdfdf',
        indexMathTitleTextColor: '#000',
        indexMathItemTitleTextColor: '#001133',
        indexMathItemNameTextColor: '#001133',
        indexMathItemTimeTextColor: '#001133',
    },



]