
import { useRoute } from "vue-router";
import { onActivated } from 'vue'
/**
 * 记录该路由上次滚动的位置，下次回来还原
 * TODO 可扩展为多个，可抽出为mixin
 * @param {*} name 路由的name
 */
 export function useScroll(ref=window) {
    const route = useRoute()
   
    let scrollTop = 0
    let name = route.name
    ref.onscroll = ()=>{
        if(route.name != name) return
        if(ref === window){
            scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        }else{
            scrollTop = ref.scrollTop
        }
    }
    onActivated(()=>{
        ref.scrollTo (0,scrollTop);
    })
    
}