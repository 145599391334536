function resizeBaseFontSize(){
    let rootHtml = document.documentElement,
        deviceWidth = rootHtml.clientWidth;
    if(deviceWidth < 1366){
        deviceWidth = 1366
    }
    rootHtml.style.fontSize = deviceWidth / 100 + "px"
    const iframe =  document.getElementById('iframe')
    if(iframe){
        iframe.style.transform = 'scale('+ deviceWidth / 1920 +')'
    }
    const iframe2 =  document.getElementById('iframe2')
    if(iframe2){
        iframe2.style.transform = 'scale('+ deviceWidth / 1920 * 1.5 +')'
    }
}
resizeBaseFontSize();
window.addEventListener("resize", resizeBaseFontSize, false);
window.addEventListener("orientationchange", resizeBaseFontSize, false);