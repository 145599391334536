<template>
  <el-dialog title="分享作品" width="500px" v-model="visibility" append-to-body>
    <div style="text-align: center">
      <div class="qrcode" ref="qrcode"></div>
      <p>微信扫一扫 立即分享</p>
    </div>
  </el-dialog>
</template>
<script>
import { qrcanvas } from "qrcanvas";
import {
  getCurrentInstance,
  nextTick,
  ref,
  watch,
  watchEffect,
} from "vue";
export default {
  components: {},
  props: {
    modelValue: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  setup(props) {
    const { emit } = getCurrentInstance();

    let canvas;
    nextTick(() => {
      canvas = qrcanvas({
        data: props.url,
        size: 180,
      });
    });
    const qrcode = ref(null);
    const visibility = ref(props.modelValue);
    watch(
      () => props.modelValue,
      value => {
        visibility.value = value;
        if (value) {
          nextTick(() => {
            qrcode.value.innerHTML = "";
            qrcode.value.appendChild(canvas);
          });
        }
      }
    );
    watchEffect(() => {
      emit("update:modelValue", visibility.value);
    });

    return {
      qrcode,
      visibility,
    };
  },
};
</script>