import request from '@/utils/request'


export function detailInfo(id){
    return request({
        url: `/api/app/examStudentAnswer/${id}/public`,
        data: {id}
    })
}

export function sb3List(PageIndex=1, PageSize=10,info){
    return request({
        url: '/api/app/examStudentAnswer/public',
        data:{
            PageIndex, PageSize, ...info
        },
        loading: true
    })
}

export function info(id){
    return request({
        url: `/api/app/programMatch/${id}/public`,
        data: {
            id
        }
    })
}

export function getMatchSetting(){
    return request({
        url: '/api/app/programMatchSetting'
    })
}

export function matchList(){
    return request({
        url: '/api/app/programMatch/publicList',
        data: {
            pageIndex: 1,
            pageSize: 1000,
            status: 1
        }
    })
}
