module.exports = {
  title: '码码乐学生端',
  storePrefix: 'Student-',
  tenantDomain: 'qzyz',
  debug: false, // 是否是测试阶段
  // 是否开启超时判断
  isTimeout: true,
  scratchUrl: 'https://tool.mamale.vip/',
  pythonUrl: 'https://python.mamale.vip/',
  jrUrl: 'https://jr.mamale.vip/editor.html',
  appId: 'CodeABC_App',

  appSecret: '1q2w3e*',
  // 正式版接口
  apiUrl: 'https://api.mamale.vip',
  // 测试版接口
  testApiUrl: 'https://lc-api.mamale.vip',
}
