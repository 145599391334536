import { createRouter, createWebHashHistory } from "vue-router";
import store from "../store/index";
import { isTimeout,tenantDomain } from "@/settings";
// 页面组件
import noAuth from "@/views/error-page/401";
import index from "@/views/index/index.vue";
import resultDetail from "@/views/detail/index";
import download from "@/views/download/index";
import match from "@/views/match/index";
import matchDetail from "@/views/match/detail";
import login from "@/views/login/index";
import themeList from "@/theme/theme.js";
import { ElLoading } from "element-plus";
import { useChangeTheme } from "@/hooks/theme";

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
    // component: () => import('@/pages/index/index.vue')    // 路由懒加载
    // component: () => import(/* webpackChunkName: "index-model" */ '@/pages/index/index.vue')  // 路由分组懒加载
  },
  {
    path: "/detail",
    name: "detail",
    component: resultDetail,
  },
  {
    path: "/401",
    name: "401",
    component: noAuth,
  },
  {
    path: "/download",
    name: "download",
    component: download,
  },
  {
    path: "/match",
    name: "match",
    component: match,
  },
  {
    path: "/match/detail",
    name: "matchDetail",
    component: matchDetail,
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// 前置路由守卫
router.beforeEach(async (to, from, next) => {
  const isInitTenant = store.getters.isInitTenant;
  let tenantInfo = store.getters.tenantInfo;
  let curDate = store.getters.curDate;
  let studentVersion = store.getters.studentVersion;
  console.log(studentVersion, "studentVersionstudentVersionstudentVersion");
  if (!isInitTenant) {
    let isLoading = true;
    let loading;
    setTimeout(() => {
      if (isLoading) {
        loading = ElLoading.service({
          fullscreen: true,
          background: "rgba(255,255,255,.2)",
          text: "努力加载中 . . . ",
        });
      }
    }, 500);
    tenantInfo = await store.dispatch("user/publicInfo");
    curDate = await store.dispatch("user/pCurrentDateTime");
     studentVersion = await store.dispatch("user/studentVersionInfo");
    if (studentVersion == 2) {
      let domain = location.hostname
      if (domain === 'localhost') {
        domain = tenantDomain + '.mamale.vip'
      }
      const targetUrl = location.protocol + '//' + domain + "/ai/#/";
      window.location.replace(targetUrl);
    }
    await store.dispatch("user/getCurThemeImg");

    useChangeTheme(store.getters.curTheme);

    // 设置文档标题
    document.title = tenantInfo.name;

    // TODO 初始化主题
    const ThemeType = 0;
    if (ThemeType > 0) {
      const theme = themeList[ThemeType - 1];
      Object.keys(theme).map((key) =>
        document
          .getElementsByTagName("body")[0]
          .style.setProperty(`--${key}`, theme[key])
      );
    }
    // 不需要显示Loading了, 如果有loading，那么就关闭
    isLoading = false;
    if (loading) {
      await new Promise((resolve) => setTimeout(resolve, 300));
      loading.close();
    }
  }
  // TODO 切换样式
  if (isTimeout) {
    const beginDate = new Date(tenantInfo.startTime);
    const endDate = new Date(tenantInfo.endTime);
    if (
      (curDate < beginDate && tenantInfo.startTime) ||
      (curDate > endDate && tenantInfo.endTime)
    ) {
      if (to.name === "401") {
        next();
        return;
      } else {
        next({ name: "401" });
        return;
      }
    }
  }
  next();
});

export default router;
