<template>
  <div id="index-header">
    <img :src="logo" alt class="logo" />
    <template v-for="(item, index) in navList">
      <div
        v-if="item.type !== 'scratch' || (canFreeCreate && item.type === 'scratch')"
        class="header-nav"
        @click="onToPage(item)"
        :key="index"
        @mouseenter="OnToenter(item.type)"
        @mouseleave="OnToleave(item.type)"
      >
        <div class="nav-top">
          <div :class="selectType === item.type ? 'top1' : 'top1-none'">
            {{ item.name }}
            <div class="nav-parent" v-if="showParentScode && item.type == 'parent'">
              <div class="parent-tri"></div>
              <div
                class="parent-box"
                @mouseenter="
  showParentScode = true
showAll = false;
                "
                @mouseleave="
  showParentScode = false
showAll = false;
                "
              >
                <div class="qrcode" ref="qrcode" id="qrcode"></div>
                <span>微信扫一扫 立即关注</span>
              </div>
            </div>
            <!-- 我要创作下拉框 -->
            <div class="nav-create" v-if="showCreate && item.type == 'scratch'">
              <div class="relative-tri"></div>
              <div class="relative-box">
                <div class="relative-item" @click="OnToTurn(1)">图形化编程</div>
                <div class="relative-item" @click="OnToTurn(2)">scratchJr</div>
                <div class="relative-item" @click="OnToTurn(0)">Python</div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="../../../assets/images/bottom-nav.png"
          :class="selectType == item.type ? 'nav-bottom' : 'nav-bottom1'"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { reactive, toRefs, nextTick, getCurrentInstance } from "vue"
import { useStore } from "vuex"
import { useToCreate } from "./logic"
import { useRouter } from "vue-router"
import { qrcanvas } from "qrcanvas"
import { getMatchSetting, matchList } from '@/api/match'

export default {
  methods: {
    enterCreate () { },
    leaveCreate () { },
  },
  setup () {
    const { emit } = getCurrentInstance()

    // 初始化二维码相关数据
    const qrcodeLogo = require("@/assets/parent.jpg")
    const image = document.createElement("img")
    image.src = qrcodeLogo
    image.height = 50
    image.width = 50
    const parentUrl = location.origin + "/parent/#/"
    let canvas
    // 初始化二维码的canvas
    nextTick(() => {
      canvas = qrcanvas({
        logo: {
          image
        },
        data: parentUrl,
        size: 180
      })
    })

    const store = useStore()
    const state = reactive({})
    const router = useRouter()

    // 初始化导航的名称跟数组
    state.navList = [
      { name: "官网首页", type: "index" },
      { name: "我要创作", url: "https://tool.mamale.vip/", type: "scratch" },
      { name: "机构端", url: "/admin", type: 'admin' },
      { name: "教师端", url: "/teacher", type: 'teacher' },
      { name: "学生端", url: "/study", type: 'student' },
      { name: "家长端", url: "parent", type: "parent" },
      { name: "资源下载", url: "download", type: "download" },
    ]

    // TODO 判断是机构还是学校还是赛事主办方，进行处理

    // 设置站点信息 跟 logo
    const siteInfo = store.getters.tenantInfo
    state.logo = siteInfo.logo
    state.siteInfo = siteInfo
    state.showParentScode = false
    state.tenantTypeOption = {
      0: '机构端',
      1: '学校端',
      2: '主办方',
      3: '机构端'
    }

    state.teacherTypeOption = {
      0: '教师端',
      1: '教师端',
      2: '教师端',
      3: '教师端'
    }

    state.tenantType = state.tenantTypeOption[Number(siteInfo.tenantType)] || '机构端'
    state.navList[2].name = state.tenantType
    state.teacherType = state.teacherTypeOption[Number(siteInfo.tenantType)] || '教师端'
    state.navList[3].name = state.teacherType
    console.log(state.siteInfo.tenantType)
    if (Number(state.siteInfo.tenantType) == 3) {
      const list = [state.navList[0], state.navList[2], state.navList[3], state.navList[4], state.navList[5]]
      state.navList = list
    }
    if (Number(state.siteInfo.tenantType) == 2) {
      state.navList.splice(5, 1)
    }

    getMatchSetting().then(res => {
      state.programMatchOpenStatus = res.programMatchOpenStatus
      state.matchResultOpenStatus = res.matchResultOpenStatus
      if (res.programMatchOpenStatus) {
        // 需要新增一个
        state.navList.push({
          name: '赛事',
          type: 'match'
        })
        matchList().then(result => {
          state.matchList = result.items
        })
      }
    })
    // 导航移入移出相关逻辑
    state.OnToleave = type => {
      if (type === 'parent') {
        state.showParentScode = false
        state.showAll = false
      }

      if (type === 'scratch') {
        state.showCreate = false
      }
    }
    state.OnToenter = type => {
      if (type === 'parent' && Number(state.siteInfo.tenantType) !== 2) {
        state.showParentScode = true
        state.showAll = true
        nextTick(() => {
          document.getElementById("qrcode").innerHTML = ""
          document.getElementById("qrcode").appendChild(canvas)

        })
      }

      if (type === 'scratch') {
        state.showCreate = true
        state.scratchState = true
      }
    }
    state.selectType = 'index'
    // 页面跳转的逻辑
    state.onToPage = item => {
      switch (item.type) {
        case "index":

          state.selectType = item.type
          emit('onShowIndex')
          break
        case "match":

          state.selectType = item.type
          emit('onShowMatch')
          break
        case "download":
          router.push("/download")

          break
        case "scratch":
        case "parent":
        case "all":
          break
        default:
          window.open(
            ~item.url.indexOf("://") ? item.url : location.origin + item.url
          )
      }
    }
    // 点击创作的相关逻辑
    useToCreate(state)

    return {
      ...toRefs(state),
    }
  },
}
</script>

<style lang="less" scoped>
#index-header {
  width: 100%;
  height: 103px;
  background: @navBgColor;
  color: @fontColor;
  // background: rgba(15, 9, 85, 1);
  display: flex;
  align-items: center;
  position: relative;

  .logo {
    /*width: 150px;*/
    height: 71px;
    margin-left: 361px;
  }

  .header-nav {
    display: flex;
    // flex-direction: column;
    width: 112px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .nav-top {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      align-items: center;
      margin-top: 31px;

      .top1 {
        font-size: 18px;
        font-family: "bold";
        font-weight: bold;
        // color: rgba(255, 255, 102, 1);   todo这个好像没什么用
        background: @navActiveColor;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .top1-none {
        font-size: 18px;
        font-family: "Microsoft YaHei";
        font-weight: 400;
        color: @indexNavColor;
        position: relative;

        .nav-parent {
          position: absolute;
          right: -90px;
          top: 70px;

          background: @indexNavFollowParentBgColor;
          box-shadow: 0px 0px 0px 1px #f0f0f0;
          z-index: 100;

          .parent-tri {
            // todo这个好像没用上
            border-width: 15px;
            border-style: solid;
            border-color: transparent @indexNavFollowParentBgColor transparent
              transparent;
            transform: rotate(90deg);
            position: absolute;
            left: 103px;
            top: -28px;
          }
          .qrcode {
            text-align: center;
            width: 196px;
            height: 196px;
          }
          .parent-box {
            display: flex;
            flex-direction: column;
            width: 235px;
            height: 272px;
            padding: 15px 18.8px 0 20.2px;

            span {
              font-size: 18px;
              color: @indexNavFollowParentTextColor;
              margin-top: 22px;
            }
          }
        }

        .nav-create {
          position: absolute;
          right: -35px;
          top: 70px;

          background: @indexNavCreateBgColor;

          z-index: 100;

          .relative-tri {
            border-width: 15px;
            border-style: solid;
            border-color: transparent @indexNavCreateBgColor transparent
              transparent;
            transform: rotate(90deg);
            position: absolute;
            left: 58px;
            top: -28px;
          }

          .relative-box {
            display: flex;
            flex-direction: column;
            width: 145px;
            height: 150px;
            padding-top: 6px;

            .relative-item {
              width: 90%;
              height: 46px;
              margin-left: 5%;
              display: flex;
              align-items: center;
              justify-content: center;
              border-bottom: 1px dashed @indexNavCreateItemBorderColor;
              font-size: 18px;
              color: @indexNavCreateItemTextColor;
              &:hover {
                color: @indexNavCreateItemHoverColor;
              }
            }

            .relative-item:last-child {
              border: none;
            }
          }
        }

        // .nav-relative {
        //   position: absolute;
        //   right: -50px;
        //   top: 70px;

        //   background: #fff;

        //   z-index: 100;

        //   .relative-tri {
        //     border-width: 15px;
        //     border-style: solid;
        //     border-color: transparent #fff transparent transparent;
        //     transform: rotate(90deg);
        //     position: absolute;
        //     left: 78px;
        //     top: -30px;
        //   }

        //   .relative-box {
        //     display: flex;
        //     flex-direction: column;
        //     width: 180px;
        //     height: 215px;
        //     padding-top: 6px;
        //     padding-bottom: 20px;

        //     .relative-item {
        //       width: 90%;
        //       height: 46px;
        //       margin-left: 5%;
        //       display: flex;
        //       align-items: center;
        //       justify-content: center;
        //       border-bottom: 1px dashed #333;
        //       font-size: 18px;
        //       color: rgba(51, 51, 51, 1);
        //     }

        //     .relative-active {
        //       color: #000066;
        //     }

        //     .relative-item:last-child {
        //       border: none;
        //     }
        //   }
        // }
      }
    }

    .nav-bottom {
      width: 112px;
      height: 6px;
    }

    .nav-bottom1 {
      display: none;
    }
  }

  .header-nav {
    margin-left: 25px;

    &:nth-child(2) {
      margin-left: 220px;
    }
  }
  // todo 关注家长，上面有一个样式不一样，联网检查下
  .nav-parent {
    position: absolute;
    right: 340px;
    top: 103px;

    background: #fff;

    z-index: 100;

    .parent-tri {
      border-width: 15px;
      border-style: solid;
      border-color: transparent #fff transparent transparent;
      transform: rotate(90deg);
      position: absolute;
      left: 103px;
      top: -30px;
    }

    .parent-box {
      display: flex;
      flex-direction: column;
      width: 235px;
      height: 272px;
      padding: 15px 18.8px 0 20.2px;

      img {
        width: 196px;
        height: 196px;
      }

      span {
        font-size: 18px;
        color: rgba(0, 0, 102, 1);
        font-size: 18px;
        color: rgba(0, 0, 102, 1);
        margin-top: 22px;
        text-align: center;
      }
    }
  }
}
</style>