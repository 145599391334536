<template>
  <div class="resources-list">
    <el-table :data="list" height="100%">
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="creationTime" label="创建时间" />
      <el-table-column label="操作" width="256">
        <template v-slot="scope">
          <el-button type="text" @click="onDownLoad(scope.row)">点击下载</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="page" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
  </div>
</template>

<script>
import { fileLib } from "@/api/download";
import { reactive } from 'vue';

export default {
  name: "list",
  props: [],
  setup () {
    const state = reactive({
      searchInfo: {},
      list: [],
      total: 0,
      page: 1,
      pageSize: 10
    })

    state.getData = () => {
      fileLib(state.page, state.pageSize, state.searchInfo).then((res) => {
        state.list = res.items.map(item => {
          return item
        });
        state.total = res.totalCount
      })
    }
    state.search = searchInfo => {
      state.page = 1
      state.searchInfo = searchInfo
      state.getData()
    }

    // 初始化数据
    state.getData()

    // 页数变化
    state.handleSizeChange = size => {
      state.pageSize = size
      state.getData()
    }
    // 第几页变化
    state.handleCurrentChange = size => {
      state.page = size
      state.getData()
    }
    // 下载方法
    state.onDownLoad = row => {
      window.location.href = row.url
    }
    return state
  }

};
</script>

<style lang="less" scope>
.resources-list {
  height: 100%;
  font-size: 12px !important;
  .pagination {
    position: absolute;
    bottom: 0;
    left: 20%;
    right: 0;
    height: 58px;
    text-align: center;
  }
}
</style>
