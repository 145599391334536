import { useRouter } from "vue-router"
import { ElMessageBox } from 'element-plus'
import { useStore } from "vuex"

import { jrUrl, scratchUrl, pythonUrl } from '@/settings'
import { getResultSet, getInfo } from "@/api/user"

import { list, publicList } from "@/api/result"



/**
 * 根据type,去往不同的创作工具
 */
export function useToCreate (state) {
  const store = useStore()
  const router = useRouter()

  // 是否可以自由创作
  state.canFreeCreate = false
  getResultSet().then((res) => {
    state.canFreeCreate = res.isFreeCreate
    state.isLoginFreeCreate = res.isLoginFreeCreate
  })
  // 默认不显示创作下拉弹框
  state.showCreate = false
  state.OnToTurn = type => {
    const logic = () => {
      const str = state.isLoginFreeCreate ? '创作作品需要登录，请点击立即登录' : '登录后创作的作品可以保存，是否前往登录'
      ElMessageBox.confirm(str, {
        title: '温馨提示',
        confirmButtonText: '立即登录',
        cancelButtonText: '不登录创作',
        distinguishCancelAndClose: true,
        showCancelButton: !state.isLoginFreeCreate,
        type: 'info'
      }).then(() => {
        router.push('/login')
      }).catch((e) => {
        if (e == 'cancel') {
          toScratch(false, type)
        }
      })
    }
    if (store.getters.token) {
      getInfo().then(() => toScratch(true, type)).catch(logic)
    } else {
      logic()
    }
  }

  // 前往scratch
  const toScratch = (isLogin, type) => {
    const tenantApi = encodeURIComponent(store.getters.tenantsApi)
    let toolUrl

    switch (type) {
      case 0:
        toolUrl = pythonUrl
        break
      case 1:
        toolUrl = scratchUrl
        break
      case 2:
        toolUrl = jrUrl
        break
    }
    let url = toolUrl + `?tenantApi=${tenantApi}&domain=${location.host}`

    if (isLogin) {
      const token = encodeURIComponent(
        store.getters.token.access_token
      )
      url += `&result=1&token=${token}`
    }
    window.open(url)
  }
}

/**
 *  作品导航栏切换,获取作品列表
 */
export function useGetResult (state) {
  state.isPlatOpenResult = false
  state.isSeeCommunityResult = false
  getResultSet().then((res) => {
    state.isPlatOpenResult = res.isPlatOpenResult
    state.isSeeCommunityResult = res.isSeeCommunityResult
  })

  state.showActive = true//判断作品点击状态
  state.isGood = false//作品是否精选
  state.page = 1//本机构
  state.resultList = []
  state.totalCount = 0
  state.page2 = 1//本机构
  state.resultList2 = []
  state.totalCount2 = 0

  state.getResultList = () => {
    state.page = state.page + 1
    if (state.resultList.length >= state.totalCount) {
      return state.error("没有更多作品了")
    }

    list(state.page, 10, {
      IsGood: state.isGood
    }).then(res => {

      state.totalCount = res.totalCount
      state.resultList = state.resultList.concat(res.items)
    })
  }
  state.getResultList2 = () => {
    state.page2 = state.page2 + 1
    if (state.resultList2.length >= state.totalCount2) {
      return state.error("没有更多作品了")
    }
    let apiUrl = list
    let activeItem = null
    if (state.activeId == 0) {
      apiUrl = publicList
      activeItem = null
    } else {
      apiUrl = list
      activeItem = state.activeItem
    }
    apiUrl(state.page2, 10, { Filter: state.searchName, isGood: state.isGood }, activeItem).then(res => {
      state.totalCount2 = res.totalCount
      state.resultList2 = state.resultList2.concat(res.items)
    })

  }

  state.OnSelectResult = (statu, isGood) => {
    state.showActive = statu
    state.isGood = isGood
    state.searchName = ''
    state.searchInput = ''
    if (statu) {
      state.page = 0
      state.resultList = []
      state.totalCount = 5
      state.getResultList()
      return
    }
    state.page2 = 0
    state.resultList2 = []
    state.totalCount2 = 5
    state.getResultList2()
  }
  state.OnSelectResult(true, false)




}
