import request from '@/utils/request'
import { getAppBase64 } from '@/utils'
import store from '@/store'

// 登录方法
export function login () {
    return request({
        url: 'api/users/my',
    })
}

// 获取token
export function getToken (data) {
    data = {
        grant_type: 'password',
        // validation_type: 'mobile',
        ...data
    }
    const headers = {
        Authorization: 'Basic ' + getAppBase64()
    }
    const url = 'https://oauth.mamale.vip/connect/token' + (store.getters.tenantInfo.id ? '?__tenant=' + store.getters.tenantInfo.id : '')
    return request({
        url,
        method: 'POST',
        data,
        headers,
        token: true,
        loading: true
    })
}

export function getInfo () {
    return new Promise((resolve, reject) => {
        request({
            url: '/api/app/student/studentInfo',
            method: 'GET',
            loading: true
        }).then(res => {
            resolve(res)
        }).catch(() => {
            reject()
        })
    })
}


// 修改密码
export function modifyPwd (OriginalPassword, Password) {
    return request({
        url: `api/users/my/changePassword?Password=${Password}`,
        method: 'PUT'
    })
}

// 发送验证码
export function sendSms (query) {
    return request({
        url: 'api/Sms/SendSms',
        method: 'get',
        params: query
    })
}

// 忘记密码
export function resetPwd (data) {
    return request({
        url: 'api/Passport/ResetPwd',
        method: 'post',
        data
    })
}

export function my () {
    return request({
        url: 'api/users/my',
        method: 'GET',
    })
}

//生成临时关注二维码：
export function publicTicketTemp (data) {
    return request({
        url: 'api/weiXin/qrcode/publicTicketTemp',
        method: 'POST',
        data,
        json: true
    })
}

// 查看作品设置
export function getResultSet () {
    return request({
        url: `/api/app/resultSetting`,
        method: 'GET'
    })
}
// 查看界面模板
export function getThemeSet () {
    return request({
        url: `/api/app/resultSetting/theme`,
        method: 'GET'
    })
}