<template>
 <div class="resources-container"  ref="matchNode">
    <ReturnBtn @getReturnUrl="onRetrun"/> 
    <div class="content">
      <div class="tab">
        <div
          class="item"
          :style="`border:2px solid ${item.color};${
            index == activeIndex ? 'background:' + item.color : ''
          }`"
          v-for="(item, index) in tabList"
          :key="item.key"
          @click="onChangeTab(index)"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="info">
        <div v-html="info[tabList[activeIndex].key]" v-if="info.id"></div>
      </div>
    
    </div>
      <div class="list">
        <div class="title">赛事作品</div>
        <div class="show-center">
          <div class="center-item" v-for="item in sb3List" :key="item.id" @click="$router.push('/match/detail?id='+ item.id + '&matchId='+ id)">
            <img :src="item.coverImg" alt class="center-top" />
            <span class="center-center">{{ item.studentDto.name }}的作品</span>
            <div class="item-bottom">
              <div class="bottom-left">
                <div class="left-border">
                  <img src="../../assets/images/show-img.png" alt />
                </div>
                <span v-if="item.studentDto">{{ item.className }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="center-bottom" @click="handleGetSb3List" v-if="!noMore">
          <span>MORE+</span>
          <span>查看更多</span>
        </div>
      </div>
  </div>
</template>

<script>
import ReturnBtn from "@/components/ReturnBtn";
import { info,getMatchSetting,sb3List } from "@/api/match";
import { onMounted, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useScroll } from '@/hooks/scroll'
export default {
  components: { ReturnBtn },
   name: 'match',
    data(){
        return {
                 tabList: [
        {
          title: "比赛说明",
          key: "matchContent",
          color: "#ECC03B",
        },
        {
          title: "赛事流程",
          key: "flowContent",
          color: "#CC3399",
        },
        {
          title: "作品要求",
          key: "requireContent",
          color: "#0033FF",
        },
        {
          title: "大赛奖项",
          key: "awardContent",
          color: "#FF6633",
        },
        {
          title: "作品申报",
          key: "signContent",
          color: "#00A73C",
        },
      ],
        }
    },
    setup () {
        const route = useRoute()
        const router = useRouter()
        const state = reactive({
            info: {},
            canSee: false,
            page: 0,
            pageSize: 12,
            noMore: false,
            activeIndex: 0,
            sb3List: [],
            matchNode: null,
            id: route.query.id
        })
        onMounted(()=>{
           useScroll(state.matchNode)
        })
        const id = route.query.id
        // 获取比赛详情
        info(id).then(res=>{
            state.info = res
        })
        // 获取赛事列表
        state.handleGetSb3List = () =>{
              state.page = state.page + 1;
                sb3List(state.page, state.pageSize, {
                    ProgramMatchId: id,
                }).then((res) => {
                    state.sb3List = state.sb3List.concat(res.items)
                    if (state.sb3List.length >= res.totalCount) {
                        state.noMore = true;
                    }
                });
        }

        // 是否显示赛事列表
        getMatchSetting().then((res) => {
            state.canSee = res.matchResultOpenStatus;
             if (state.canSee) {
                // 获取赛事作品列表
                state.handleGetSb3List();
            }
         });
        // 状态栏点击 
        state.onChangeTab = index => state.activeIndex = index
        state.onRetrun = () => router.push('/')
        return state
    }
}
</script>

<style lang="less" scoped>

.resources-container {
  background: url("../../assets/images/courses/index-bg.png") no-repeat;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  position: fixed;
  overflow-y: scroll;
  .list {
    padding: 0px 200px 20px 245px;
    .title {
      height: 28px;
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      line-height: 43px;
    }
    .center-bottom {
      cursor: pointer;
      width: 161px;
      height: 55px;
      border: 1px solid rgba(236, 192, 59, 1);
      border-radius: 5px;
      text-align: center;
      line-height: 55px;
      position: relative;
      left: 50%;
      transform: translateX(-50% - 25px);
      margin-top: 76px;

      span:first-child {
        font-size: 20px;
        font-family: bold;
        color: rgba(236, 192, 59, 1);
        position: absolute;
        left: 44px;
        top: 0px;
      }

      span:nth-child(2) {
        font-size: 28px;
        font-family: bold;
        color: rgba(255, 255, 255, 1);
        opacity: 0.05;
      }
    }
    .show-center {
      display: flex;
      flex-wrap: wrap;
      .center-item {
        cursor: pointer;
        width: 209px;
        height: 255px;
        background: #282264;
        margin-right: 37px;
        margin-top: 50px;
        border: 1px solid #504c92;
        // opacity: 0.1;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .center-top {
          width: 100%;
          height: 140px;
          border-radius: 10px 10px 0 0;
        }

        .center-center {
          font-size: 20px;
          color: rgba(255, 255, 255, 1);
          margin-left: 11px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .item-bottom {
          margin-left: 10px;
          margin-bottom: 10px;
          display: flex;
          height: 30px;
          align-items: center;
          justify-content: space-between;

          .bottom-left {
            display: flex;
            height: 100%;
            align-items: center;

            .left-border {
              width: 31px;
              height: 30px;
              background: linear-gradient(
                230deg,
                rgba(113, 160, 195, 1),
                rgba(255, 255, 255, 1),
                rgba(178, 204, 222, 1)
              );
              border-radius: 50%;
              padding-left: 2.1px;
              padding-top: 3px;

              img {
                width: 26px;
                height: 25px;
                border-radius: 50%;
              }
            }

            span {
              margin-left: 12px;
              font-size: 16px;
              color: rgba(121, 145, 231, 1);
              width: 80px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .bottom-right {
            padding-top: 5px;
            display: flex;
            margin-right: 10px;

            img {
              width: 20px;
              height: 15px;
            }

            span {
              margin-left: 10px;
              font-size: 16px;
              color: rgba(236, 192, 59, 1);
            }
          }
        }
      }
    }
  }
  .content {
    padding: 80px 240px 20px 240px;
    .info {
      overflow-y: scroll;
      margin-top: 20px;
      padding: 52px 42px;
      width: 1440px;
      height: 500px;
      background: #716bb1;
      border-radius: 20px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
    }
    .tab {
      overflow: hidden;
      margin-left: 100px;
      user-select: none;
      .item {
        cursor: pointer;
        float: left;
        background: #262960;
        color: white;
        margin-right: 54px;
        width: 206px;
        height: 77px;
        line-height: 77px;
        text-align: center;
        border-radius: 10px;
      }
    }
  }
  .container-return {
    cursor: pointer;
    width: 58px;
    position: absolute;
    left: 44px;
    top: 59px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .container-contents {
    position: absolute;
    left: 358px;
    top: 124px;
    width: 1200px;
    height: 745px;
    min-height: 745px;
    background: rgba(255, 255, 255, 1);
    border-radius: 30px;
    padding: 58px 0;

    .plane {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
    }

    .tree {
      padding-left: 10px;
      width: 240px;
      height: 100%;
      overflow-y: scroll;
    }

    .list {
      padding-left: 18px;
      width: 80%;
      height: 100%;
    }
  }
}
</style>