<template>
    <div class="match" ref="matchNode">
        <div class="title">赛事活动</div>
        <div class="match-list">
            <div class="item" v-for="item in matchList" :key="item.id" @click="onToMatch(item.id)">
                <img :src="item.coverImg" alt />
                <div class="name">{{ item.name }}</div>
                <p class="time">
                    比赛时间：{{ item.matchTime }}
                    <br />
                    报名时间：{{ item.signEndTime }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { matchList } from "@/api/match";
import { reactive } from "vue";
import { useRouter } from 'vue-router';
export default {
    setup() {
        const router = useRouter()
        const state = reactive({
            matchList: [],
        });

        matchList().then(result => {
            state.matchList = result.items
        });
        state.onToMatch = id => router.push({
            name: 'match',
            query: { id }
        })
        return state
    },
};
</script>

<style lang="less" scoped>
.match {
    background: @baseColor;
    padding: 86px 296px;
    padding-right: 250px;
    color: white;
    .title {
        height: 28px;
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: @indexMathTitleTextColor;
        line-height: 43px;
    }
    .match-list {
        margin-top: 34px;
        overflow: hidden;
        .item {
            position: relative;
            cursor: pointer;
            width: 311px;
            height: 280px;
            background: @indexMathItemBgColor;
            border-radius: 10px;
            margin-right: 27px;
            overflow: hidden;
            float: left;
            border: @indexMathItemBorder;
            img {
                background: white;
                width: 100%;
                height: 140px;
            }
            .name {
                padding: 0 20px;
                margin-top: 10px;
                margin-bottom: 5px;
                //line-height: 43px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: @indexMathItemNameTextColor;
            }
            .time {
                padding: 0 20px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: @indexMathItemTimeTextColor;
                line-height: 30px;
                margin: 0;
                position: absolute;
                bottom: 10px;
            }
        }
    }
}
</style>