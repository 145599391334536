
import { getComment, publicComment } from "@/api/result";
import { ElMessage  } from "element-plus";

/**
 *  作品评论
 */
export function useGetResultComment(state, store, router) {
    // 加载更多
    state.OnToGetMore = () => {
        if (state.total >= state.pageIndex * state.pageSize) {
            state.pageIndex++
            state.getCommentList()
        }
    }
    // 获取评论列表
    state.getCommentList = () => {
        state.commentList = state.commentListAll
        getComment(router.currentRoute.value.query.workerId, {
            pageIndex: state.pageIndex,
            pageSize: state.pageSize
        }).then(res => {
            state.commentList = state.commentList.concat(res.items);
            state.commentListAll = state.commentList
            state.total = res.totalCount
            if (res.totalCount > state.pageIndex * state.pageSize) {
                state.showMoreState = true
            } else {
                state.showMoreState = false
            }
        });
    }
    // 提交评论
    state.OnToSubmit = () => {
        if(!state.commentContent){
            return  ElMessage.warning({
                message: '请输入评论内容',
                type: 'warning'
              });
        }
        publicComment(router.currentRoute.value.query.workerId, { content: state.commentContent }).then(() => {
            var date = new Date();
            let creat = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' '
            let user = { 'user': store.state.user.userInfo, 'content': state.commentContent, 'creationTime': creat }
            state.commentContent = ""
            state.commentListAll = JSON.parse(JSON.stringify(state.commentList))
            state.commentList.push(user)
        });
    }
}
