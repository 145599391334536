<template>
  <div class="detail-container">
    <ReturnBtn @getReturnUrl="onReturn" />
    <div class="container-contents">
      <div class="container-content"></div>
      <div class="content-user">
        <img src="../../assets/images/user.png" alt />
      </div>
      <div class="content-name">{{ info.student.name }}</div>
      <div class="content-time">
        {{ info.creationTime.split(" ")[0] }} 发布<span v-if="isCommon">,
          来源于：{{ info.appTenant ? info.appTenant.name : "" }}</span>
      </div>
      <div class="content-header">
        <div class="content-title">
          <div class="title-left"></div>
          <span>{{ info.name }}</span>
          <div class="title-right"></div>
        </div>
      </div>
      <div class="content-works">
        <div class="works-content">
          <img src="../../assets/images/courses/rect.png" alt />
          <span>作品说明：</span>
        </div>
        <div class="works-all">
          <span>{{ info.content }}</span>
        </div>
        <div class="works-content content1">
          <img src="../../assets/images/courses/rect.png" alt />
          <span>操作说明：</span>
        </div>
        <div class="works-all">
          <span>{{ info.information }}</span>
        </div>
        <div class="edit" @click="onEdit" v-if="info.isEdit && canEdit">
          <img src="../../assets/images/edit.png" alt />&nbsp;作品改编
        </div>
        <div class="share" @click="onShare" :class="{ edit: !(info.isEdit && canEdit) }">
          <img src="../../assets/images/share.png" alt />&nbsp;分享作品
        </div>
      </div>
      <div class="content-video" id="iframe" :style="{transform: 'scale('+scale+')'}">
        <iframe v-show="load" :src="src" frameborder="0" style="width: 483px;height: 407px" ref="iframe"
          allow="microphone;camera;midi;encrypted-media;"></iframe>
        <div class="load" v-show="!load">
          <img src="../../assets/images/loading.gif" alt style="width: 100%" />
        </div>
      </div>

      <div class="comment">
        <div class="comment-textarea">
          <img src="../../assets/images/user.png" alt />
          <div class="textarea-submit">
            <textarea name id placeholder="童鞋，别再暗中观察了，快帮我点评一下吧~" v-model="commentContent"></textarea>
            <div class="submit" @click="OnToSubmit">发表评论 </div>
          </div>
        </div>
        <!-- 评论列表 -->
        <block v-for="(item, index) in commentList" :key="index">
          <div class="comment-list">
            <img class="head" src="../../assets/images/user.png" alt />
            <div class="list-item">
              <span class="name">{{
                item.userName
                  ? item.userName
                  : item.user
                  ? item.user.userName
                    ? item.user.userName
                    : "匿名"
                  : "匿名"
              }}</span>
              <div class="content">{{ item.content }}</div>
              <div class="time">
                <span>{{ item.creationTime.split(" ")[0] }}</span>
              </div>
            </div>
          </div>
        </block>
        <div style="text-align: center; margin-top: 20px" @click="OnToGetMore"
          v-if="showMoreState && commentList.length">
          查看更多
        </div>
        <div style="text-align: center; margin-top: 20px" @click="OnToGetMore"
          v-if="!showMoreState && commentList.length">
          没有更多了~
        </div>
        <div class="comment-none" v-if="commentList.length <= 0">暂无评论</div>
      </div>
    </div>
    <Share v-model="showShare" :url="shareUrl" />
  </div>
</template>
<script>
import ReturnBtn from "@/components/ReturnBtn";
import { reactive, onMounted, ref } from 'vue';
import { useStore } from "vuex";
import { useRouter } from 'vue-router'
import { info, view } from "@/api/result";
import { scratchUrl, pythonUrl } from "@/settings";
import { useGetResultComment } from "./components/logic";
import Share from './components/Share'
import picList from '@/theme/pic'
import { ElMessageBox }  from 'element-plus'

export default {
  components: { ReturnBtn, Share },
  setup () {

    const router = useRouter()
    const state = reactive({
      load: false,
      scale: 1,
      info: {
        student: {
          name: ""
        },
        creationTime: " "
      },
      total: 0,
      pageIndex: 1,
      pageSize: 7,
      commentList: [],
      commentListAll: [],
      shareUrl: location.origin + '/parent/#/pages/share/share?id=' + router.currentRoute.value.query.workerId,
      isCommon: false,
      showMoreState: true,
      showShare: false,
      canEdit: false,
      commentContent: "",
      iframe: ref(null),
      src: '',
      themeImg: picList
    })
    // 初始化ifrmae 的加载 跟 允许的作用域
    onMounted(() => {
      state.iframe.onload = () => {
        state.load = true;
      };
      state.iframe.allow = 'microphone;camera;midi;encrypted-media;'
    })
    // 返回
    state.onReturn = () => {
      router.push("/");
    }

    const store = useStore();
    // 基础信息
    info(router.currentRoute.value.query.workerId).then(res => {
      state.info = res;
      const url = state.info.type == 1 ? pythonUrl : scratchUrl
      state.src =
        url +
        "" +
        "player.html?sb3=" +
        encodeURIComponent(state.info.sb3);
      if (store.getters.userId != state.info.user.id) {
        view(router.currentRoute.value.query.workerId);
      }
    });
    onMounted(() => {
    });
    state.isCommon = router.currentRoute.value.query.isCommon ? true : false
    state.scale = window.innerWidth / 1920 * 1.28;
    useGetResultComment(state, store, router)
    state.getCommentList()
    state.onEdit = () => {
      console.dir(store.getters)
      const token = encodeURIComponent(store.getters.token.access_token);
      const tenantApi = encodeURIComponent(store.getters.tenantsApi);
      const sb3 = encodeURIComponent(state.info.sb3);
      const name = encodeURIComponent(state.info.name);
      const resultId = encodeURIComponent(state.info.id);
      let url = state.info.type == 1 ? pythonUrl : scratchUrl
      if (!token || token == undefined || token == 'undefined') {
      
        return   ElMessageBox.confirm('请先登录', {
                title: '温馨提示',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                distinguishCancelAndClose: true,
                type: 'info'
            }).then(() => {
          router.push('/login')
        })
      }
      if (store.getters.userId === state.info.user.id) {
        url = url +
          `?result=1&role=1&tenantApi=${tenantApi}&resultId=${resultId}&token=${token}&sb3=${sb3}&title=${name}`;
      } else {
        url = url +
          `?result=1&role=1&tenantApi=${tenantApi}&token=${token}&sb3=${sb3}&title=${name}`;
      }
      window.open(url);
    }
    state.onShare = () => {
      state.showShare = true
    }
    // if(store.getters.tenantInfo && store.getters.tenantInfo.tenantType != 2){
    if (store.getters.tenantInfo) {
      state.canEdit = true
    }
    return state
  }
}
</script>

<style  lang="less" scoped>
.detail-container {
  background: url("../../assets/images/courses/index-bg.png") no-repeat;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  position: fixed;
  overflow-y: scroll;

  .container-contents {
    position: absolute;
    left: 358px;
    top: 124px;
    width: 1200px;
    min-height: 745px;
    background: @detailsInfoBgColor;
    border-radius: 30px;
    margin-bottom: 198px;
    padding-bottom: 58px;
    .container-content {
      width: 1200px;
      height: 745px;
      border-radius: 30px;
    }
    .content-user {
      position: absolute;
      left: 41px;
      top: 18px;
      width: 120px;
      height: 114px;
      background: @detailsInfoUserImgBgColor;
      border-radius: 50%;
      text-align: center;
      padding-top: 7px;
      padding-left: 2px;
      img {
        width: 103px;
        height: 98px;
        border-radius: 50%;
      }
    }
    .content-name {
      position: absolute;
      left: 190px;
      top: 35px;
      font-size: 31px;
      font-family: bold;
      color: rgba(255, 1255, 255, 1);
      -webkit-text-stroke: 1px @detailsInfoUserTextColor;
    }
    .content-time {
      position: absolute;
      left: 190px;
      top: 90px;
      font-size: 18px;
      font-family: " Microsoft YaHei";
      color: @detailsInfoDescTextColor;
    }
    .content-header {
      position: absolute;
      right: 39px;
      top: 100px;
      width: 450px;
      display: flex;
      justify-content: center;
      .content-title {
        height: 77px;
        border: 3px solid @detailsInfoTypeBorderColor;
        border-radius: 10px;
        text-align: center;
        font-size: 28px;
        font-family: bold;
        color: @detailsInfoTypeTextColor;
        display: flex;
        align-items: center;
        justify-content: center;
        .title-left {
          width: 18px;
          height: 18px;
          background: @detailsInfoTypeCircleColor;
          border-radius: 50%;
          margin-left: 15px;
        }
        span {
          margin: 0 8px;
          padding-bottom: 5px;
        }
        .title-right {
          width: 18px;
          height: 18px;
          background: @detailsInfoTypeCircleColor;
          border-radius: 50%;
          margin-right: 15px;
        }
      }
    }
    .content-works {
      position: absolute;
      left: 682px;
      top: 216px;

      .works-content {
        display: flex;
        align-items: center;
        img {
          width: 21px;
          height: 21px;
        }
        span {
          font-size: 24px;
          font-family: "Microsoft YaHei";
          color: @detailsInfoDetailsLabelTextColor;
          margin-left: 5px;
          padding-bottom: 2px;
        }
      }
      .works-all {
        width: 450px;
        max-height: 115px;
        padding-top: 10px;
        padding-left: 25px;
        overflow: hidden;
        span {
          font-size: 20px;
          font-family: "Microsoft YaHei";
          color: @detailsInfoDetailsTextColor;
        }
      }
      .share {
        user-select: none;
        // margin-left: 50%;
        cursor: pointer;
        position: absolute;
        right: -30%;
        transform: translateX(-50%);
        top: 390px;
        width: 229.6px;
        padding: 20px;
        background: @detailsInfoBtnBgColor;
        border-radius: 10px;
        font-size: 24px;
        text-align: center;
        font-weight: 400;
        color: @detailsInfoBtnTextColor;
        img {
          vertical-align: middle;
          width: 25px;
        }
      }
      .edit {
        user-select: none;
        // margin-left: 50%;
        img {
          vertical-align: middle;
          width: 25px;
        }
        cursor: pointer;
        position: absolute;
        right: 25%;
        transform: translateX(-50%);
        top: 390px;
        width: 229.6px;
        padding: 20px;
        background: @detailsInfoBtn2BgColor;
        border-radius: 10px;
        font-size: 24px;
        text-align: center;
        font-weight: 400;
        color: @detailsInfoBtn2TextColor;
      }
      .content1 {
        margin-top: 26px;
      }
    }
    #iframe {
      transform-origin: 10% 10% 0;
      width: 483px;
      height: 470px;
      .load {
        border: 1px solid @detailsInfoPreviewBorderColor;
        background: @detailsInfoPreviewBgColor;
        position: absolute;
        width: 483px;
        height: 400px;
        img {
          position: absolute;
          left: 50%;
          top: 50%;
          max-width: 80%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
    .content-video {
      position: absolute;
      left: 42px;
      top: 166px;

      video {
        width: 618px;
        height: 389px;
      }
    }

    .comment {
      width: 96%;
      min-height: 135px;
      background-color: @detailsInfoCommentBgColor;
      margin-left: 2%;
      padding-bottom: 30px;
      .comment-textarea {
        display: flex;
        padding-top: 25px;
        img {
          width: 52px;
          height: 52px;
          border-radius: 50%;
          margin-left: 50px;
        }
        .textarea-submit {
          width: 85%;
          height: 150px;
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          textarea {
            width: 100%;
            height: 85px;
            background: @detailsInfoCommentTextareaBgColor;
            border: 1px solid @detailsInfoCommentTextareaBorderColor;
            border-radius: 10px;
            padding: 10px 0 10px 10px;
            font-size: 14px;
          }
          textarea::-webkit-scrollbar {
            display: none;
          }
          textarea::-webkit-input-placeholder {
            // font-size:85px;
            color: #ccc;
          }
          .submit {
            width: 85px;
            height: 30px;
            background-color: @detailsInfoCommentBtnColor;
            color: @detailsInfoCommentTextColor;
            text-align: center;
            line-height: 30px;
            font-size: 16px;
            border-radius: 15px;
            margin-top: 10px;
            cursor: pointer;
          }
        }
      }
      //   .comment-textarea-submit {
      //     display: flex;
      //     padding-top: 25px;
      //     img {
      //       width: 52px;
      //       height: 52px;
      //       border-radius: 50%;
      //       // margin-left: 50px;
      //     }
      //     .textarea-submit {
      //       width: 90%;
      //       height: 150px;
      //       margin-left: 20px;
      //       display: flex;
      //       flex-direction: column;
      //       align-items: flex-end;
      //       textarea {
      //         width: 100%;
      //         height: 85px;
      //         background: rgba(247, 247, 247, 1);
      //         border: 1px solid rgba(204, 204, 204, 1);
      //         border-radius: 10px;
      //         padding: 10px 0 10px 10px;
      //         font-size: 14px;
      //       }
      //       textarea::-webkit-scrollbar {
      //         display: none;
      //       }
      //       textarea::-webkit-input-placeholder {
      //         // font-size:85px;
      //         color: #ccc;
      //       }
      //       .submit {
      //         width: 85px;
      //         height: 30px;
      //         background-color: #0033ff;
      //         color: #fff;
      //         text-align: center;
      //         line-height: 30px;
      //         font-size: 16px;
      //         border-radius: 15px;
      //         margin-top: 10px;
      //         cursor: pointer;
      //         margin-right: 7px;
      //       }
      //     }
      //   }
      //   .reply-textarea {
      //     display: flex;
      //     padding-top: 25px;
      //     img {
      //       width: 50px;
      //       height: 50px;
      //       border-radius: 50%;
      //       margin-left: 50px;
      //     }
      //     .textarea-submit {
      //       width: 85%;
      //       height: 150px;
      //       margin-left: 20px;
      //       display: flex;
      //       flex-direction: column;
      //       align-items: flex-end;
      //       textarea {
      //         width: 100%;
      //         height: 85px;
      //         background: rgba(247, 247, 247, 1);
      //         border: 1px solid rgba(204, 204, 204, 1);
      //         border-radius: 10px;
      //         padding: 10px 0 10px 10px;
      //         font-size: 14px;
      //       }
      //       textarea::-webkit-scrollbar {
      //         display: none;
      //       }
      //       textarea::-webkit-input-placeholder {
      //         // font-size:85px;
      //         color: #ccc;
      //       }
      //       .submit {
      //         width: 85px;
      //         height: 30px;
      //         background-color: #0033ff;
      //         color: #fff;
      //         text-align: center;
      //         line-height: 30px;
      //         font-size: 16px;
      //         border-radius: 15px;
      //         margin-top: 10px;
      //         cursor: pointer;
      //       }
      //     }
      //   }
      .comment-list {
        width: 100%;
        display: flex;
        padding-top: 25px;
        .head {
          width: 52px;
          height: 52px;
          border-radius: 50%;
          margin-left: 50px;
        }
        .list-item {
          display: flex;
          flex-direction: column;
          width: 85%;
          margin-left: 14px;

          .name {
            font-size: 14px;
            font-weight: bold;
            color: @detailsInfoCommentUserNameTextColor;
            padding-top: 6px;
          }
          .content {
            font-size: 14px;
            font-weight: 400;
            color: @detailsInfoCommentContentTextColor;
            margin-top: 14px;
          }
          .time {
            display: flex;
            font-size: 12px;
            color: @detailsInfoCommentTimeTextColor;
            width: 100%;
            height: 50px;
            align-items: center;
            justify-content: flex-end;
            span:first-child {
              padding-top: 4px;
            }
            // span:nth-child(2) {
            //   width: 1px;
            //   height: 15px;
            //   background-color: #999999;
            //   margin: 0 5px;
            // }
            // .replyit {
            //   cursor: pointer;
            //   color: #666;
            // }
            // .replyit-active {
            //   color: #0033ff;
            // }
          }
          //   .reply {
          //     width: 102%;
          //     background: rgba(247, 247, 247, 1);
          //     border-radius: 10px;
          //     margin-left: -10px;
          //     padding-bottom: 20px;
          //     .reply-items {
          //       margin-top: 20px;
          //       padding-left: 13px;
          //       width: 100%;
          //       display: flex;

          //       img {
          //         width: 50px;
          //         height: 50px;
          //         border-radius: 50%;
          //       }
          //       .reply-list {
          //         width: 98%;
          //         margin-left: 14px;

          //         .reply-name {
          //           font-size: 14px;
          //           font-weight: bold;
          //           color: rgba(0, 0, 0, 1);
          //           padding-top: 5px;
          //         }
          //         .reply-content {
          //           font-size: 14px;
          //           font-weight: 400;
          //           color: rgba(153, 153, 153, 1);
          //           margin-top: 14px;
          //         }
          //         .time {
          //           display: flex;
          //           font-size: 12px;
          //           color: #999999;
          //           width: 98%;
          //           height: 50px;
          //           align-items: center;
          //           justify-content: flex-end;
          //           span:first-child {
          //             padding-top: 4px;
          //           }
          //           span:nth-child(2) {
          //             width: 1px;
          //             height: 15px;
          //             background-color: #999999;
          //             margin: 0 5px;
          //           }
          //           .replyit {
          //             cursor: pointer;
          //             color: #666;
          //           }
          //           .replyit-active {
          //             color: #0033ff;
          //           }
          //         }
          //       }
          //     }
          //   }
        }
      }
      .comment-none {
        text-align: center;
        font-size: 30px;
        color: @detailsInfoCommentNullTextColor;
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
  }
}
</style>
