import {storePrefix} from '@/settings'
import Cookies from 'js-cookie'

const TokenKey = storePrefix + 'Admin-Token'
const UserKey = storePrefix + 'Admin-User-info'
const BusinessIdKey = storePrefix + 'Admin-Business-id'
const AvatarKey = storePrefix + 'Admin-avatar'
const TicketStateKey = storePrefix + 'Admin-ticket-state'
const FoodStateKey = storePrefix + 'Admin-food-state'
const HotelStateKey = storePrefix + 'Admin-hotel-state'
const IsTenants = storePrefix + 'Admin-is-tenants'
const TenantsID = storePrefix + 'Admin-tenants-id'
const TenantsApi = storePrefix + 'Admin-tenants-api'
export function get(key) {
  switch (key) {
    case 'userInfo':
      if (Cookies.get(UserKey)) {
        return JSON.parse(Cookies.get(UserKey))
      }
      return {}
    case 'businessId':
      return Cookies.get(BusinessIdKey)
    case 'token':
      if (Cookies.get(TokenKey)) {
        return JSON.parse(Cookies.get(TokenKey))
      }
      return ''
    case 'avatar':
      return Cookies.get(AvatarKey)
    case 'ticketState':
      return Cookies.get(TicketStateKey)
    case 'foodState':
      return Cookies.get(FoodStateKey)
    case 'hotelState':
      return Cookies.get(HotelStateKey)
    case 'isTenants':
      return Cookies.get(IsTenants)
    case 'tenantsId':
      return Cookies.get(TenantsID)
    case 'tenantsApi':
      return Cookies.get(TenantsApi)
  }
}

export function set(key, info) {
  switch (key) {
    case 'userInfo':
      return Cookies.set(UserKey, info)
    case 'businessId':
      return Cookies.set(BusinessIdKey, info)
    case 'token':
      return Cookies.set(TokenKey, info)
    case 'avatar':
      return Cookies.set(AvatarKey, info)
    case 'ticketState':
      return Cookies.set(TicketStateKey, info)
    case 'foodState':
      return Cookies.set(FoodStateKey, info)
    case 'hotelState':
      return Cookies.set(HotelStateKey, info)
    case 'isTenants':
      return Cookies.set(IsTenants, info)
    case 'tenantsId':
      return Cookies.set(TenantsID, info)
    case 'tenantsApi':
      return Cookies.set(TenantsApi, info)
  }
}

export function initUserInfo() {
  const info = Cookies.get(UserKey)
  if (info) {
    return JSON.parse(info)
  }
  return {}
}

export function initToken() {
  const token = Cookies.get(TokenKey)
  if (token) {
    return JSON.parse(token)
  }
  return ''
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
