<template>
     <div class="container-return" @click="onTurn">
      <img src="../assets/images/courses/return.png" alt />
    </div>
</template>
<script>
import { reactive } from 'vue'
export default {
    setup(props, { emit }) {
      const state = reactive({})
      state.onTurn = () =>{
         
        emit('getReturnUrl')
      }
    return state

  },
}
</script>
<style lang="less" scoped>
 .container-return {
    cursor: pointer;
    width: 58px;
    position: absolute;
    left: 44px;
    top: 59px;
    img {
      width: 100%;
      height: 100%;
    }
  }
</style>