<template>
  <div id="app">
    <ElConfigProvider :locale="zhCn">
    <router-view v-slot="{ Component }">
      <keep-alive :include="keepAlive">
        <component :is="Component"></component>
      </keep-alive>
    </router-view>
    </ElConfigProvider>
  </div>
</template>
<script>
// import { useChangeTheme } from "@/hooks/theme";
// import { useStore } from "vuex";
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'

export default {
  data () {
    return {
      keepAlive: ['index', 'match'],
      zhCn
    }
  },
  components: {
    ElConfigProvider
  },
  setup () {
    
    // const store = useStore();
    // useChangeTheme(store.getters.curTheme)
  },
};
</script>

<style lang="less">
@import "./common/less/index.less";
html {
  font-size: 19.2px; /*no*/
}
html,
body,
#app {
  min-width: 1280px; /*no*/
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
</style>
