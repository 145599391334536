import { createApp } from 'vue'
import App from './App.vue'

import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';

import 'swiper/swiper.less'
// 引入rem适配文件
import './utils/px2rem'

import store from './store'
import router from './router'

const app = createApp(App)

app.use(router).use(store).use(ElementPlus).mount('#app')
