import themeList from '@/theme/theme'
/**
 * 切换主题
 * @param {*} key 
 */
export function useChangeTheme(key){
    const theme = themeList[key]
    for(let key in theme){
        document.getElementsByTagName('body')[0].style.setProperty(`--${key}`, theme[key]);
    }
}