import { login, getToken, getThemeSet } from '@/api/user'
import { set, get, removeToken } from '@/utils/auth'
import router from '@/router/index.js'
import { getCurrTimestamp } from '@/utils'
import { publicInfo, pCurrentDateTime,studentVersionInfo } from '@/api/tenants'
import picList from '@/theme/pic'

const state = {
    token: get('token'),
    userInfo: get('userInfo'),
    name: get('userInfo').Name,
    avatar: get('avatar'),
    tenantInfo: '',
    isInitTenant: false, //首次加载租户信息
    curDate: '',
    curTheme: 0,
    curThemeImg: {},
    studentVersion:0
}

const mutations = {

    SET_TOKEN: (state, token) => {
        set('token', token)
        state.token = token
    },
    SET_USER_INFO: (state, userInfo) => {
        state.userInfo = userInfo
        state.name = userInfo.Name
        set('userInfo', userInfo)
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },

    SET_TENANT: (state, tenantInfo) => {
        state.tenantInfo = tenantInfo
        state.isInitTenant = true
    },
    SET_CURDATE: (state, data) => {
        state.curDate = data
    },
    SET_CURTHEME: (state, data) => {
        console.log(data, 'data')
        state.curTheme = data
    },
    SET_CURTHEMEIMG: (state, data) => {
        console.log(data, 'data')
        state.curThemeImg = data
    },
    SET_STUDENTVERSION: (state, data) => {
        state.studentVersion = data
    }
}

const actions = {

    // 用户登录
    login ({ commit }, data) {
        return new Promise((resolve, reject) => {
            // 首先，更新token
            getToken(data).then(res => {
                const token = {
                    ...res,
                    expires_in: res.expires_in + getCurrTimestamp()
                }
                commit('SET_TOKEN', token)
                login().then(userInfo => {
                    if (userInfo.userType !== 2) {
                        return reject('账号或者密码错误！')
                    }
                    resolve('登录成功')
                }).catch(() => {
                    reject('账号或者密码错误！')
                })
            }).catch(() => {
                reject('账号或者密码错误！')
            })
        })
    },
    // 获取用户信息
    getInfo ({ commit }) {
        return new Promise((resolve) => {
            const resolveData = {
                roles: ['organization']
            }
            commit('SET_ROLES', resolveData.roles)
            resolve(resolveData)
        })
    },

    // 退出登录
    logout ({ commit }) {
        return new Promise((resolve) => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            removeToken()
            resolve()
        })
    },

    // remove token
    resetToken ({ commit }) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            commit('SET_USER_INFO', {})
            commit('SET_ROLES', [])
            removeToken()
            resolve()
        })
    },

    // dynamically modify permissions
    async changeRoles ({ commit, dispatch }, role) {
        const token = role + '-token'

        commit('SET_TOKEN', token)
        set('token', token)

        const { roles } = await dispatch('getInfo')

        // generate accessible routes map based on roles
        const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })

        // dynamically add accessible routes
        router.addRoutes(accessRoutes)

        // reset visited views and cached views
        dispatch('tagsView/delAllViews', null, { root: true })

    },
    // 初始化租户信息
    publicInfo ({ commit }) {
        return new Promise((resolve, reject) => {
            publicInfo().then(res => {
                res.tenantsApi = 'https://' + res.sassTwoDomainSign.trim() + '-api' + res.sassTwoDomain.replace(res.sassTwoDomain.split('.')[0], '') + '/'
                commit('SET_TENANT', res)
                resolve(res)
            }).catch(() => {
                reject()
            })
        })
    },
    // 获取当前系统时间
    pCurrentDateTime ({ commit }) {
        return new Promise((resolve, reject) => {
            pCurrentDateTime().then(res => {
                commit('SET_CURDATE', new Date(res))
                resolve(new Date(res))
            }).catch(() => {
                reject()
            })
        })
    },
    // 获取当前系统配置
    getCurThemeImg ({ commit }) {
        return new Promise((resolve) => {
            getThemeSet().then(res => {
                commit('SET_CURTHEME', res.theme)
                if (res.theme === 1) {
                    commit('SET_CURTHEMEIMG', picList[1])
                    resolve(picList[1])
                } else {
                    commit('SET_CURTHEMEIMG', picList[0])
                    resolve(picList[0])
                }
            }).catch(() => {
                commit('SET_CURTHEME', 0)
                commit('SET_CURTHEMEIMG', picList[0])
                resolve(picList[0])
            })
        })
    },
    studentVersionInfo ({ commit }) {
        return new Promise((resolve, reject) => {
            studentVersionInfo().then(res => {
                commit('SET_STUDENTVERSION', res.studentVersion)
                resolve(res.studentVersion)
            }).catch(() => {
                reject()
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
