import request from '@/utils/request'
let apiName = 'result'

export function ad(){
  return request({
    url: 'api/notice/public',
    method: 'GET',
    data:{
      pageIndex: 1,
      PageSize: 20,
      Status:1 ,
      Sorting: 'sortId desc'
    }
  })
}

export function article(){
  return request({
    url: 'api/post/public',
    method: 'GET',
    data:{
      pageIndex: 1,
      PageSize: 20,
      Status:1 ,
      Sorting: 'sortId desc'
    }
  })
}

export function publicList(PageIndex=1, PageSize=10,info={},){
  if(info.IsGood === false) {
    delete  info.IsGood
  }
    return request({
        url: 'https://api.mamale.vip/api/app/result/publicDisableTenant',
        method: 'GET',
        data: { PageIndex, PageSize, Status:1,...info,Sort: 3, type: 0 },
        loading: true
    })
}

export function list(PageIndex=1, PageSize=10,info={},siteInfo=false) {
    let url =  `api/app/result/public`
    if(siteInfo){
        url = siteInfo.tenantsApi + url
    }
    if(info.IsGood === false) {
      delete  info.IsGood
    }
  return request({
    url,
    method: 'GET',
    data: { PageIndex, PageSize, Status:1,...info,Sort: 3 ,type: 0},
    loading: true
  })
}
export function info(id){
    return request({
        url: `/api/app/result/${id}/publicDisableTenant`,
        // url: `/api/app/${apiName}/${id}/public`,
        method: 'GET'
    })
}
export function view(id){
    return request({
        url: `/api/app/result/${id}/changeView`,
        method: 'POST'
    })
}
// 获取评论列表
export function getComment(id, data) {
    return request({
      url: `api/app/resultComment/publicByResultId`,
      method: 'get',
      data: {
        ResultId: id,

        ...data
      }
    })

  }
// 评论接口
export function comment(id, data) {

    return request({
      url: `api/app/${apiName}/${id}/comment`,
      method: 'POST',
      data: {
        'content': '',
        ...data
      },
      json: true
    })
  }
// 匿名评论接口
export function publicComment(id, data) {

  return request({
    url: `api/app/${apiName}/${id}/publicComment`,
    method: 'POST',
    data: {
      'content': '',
      ...data
    },
    json: true
  })
}
