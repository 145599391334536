<template>
  <div class="article-ad">
    <div class="ad">
      <div class="header">
        <div class="img">
          <img src="../../../assets/images/ad.png" alt="" />
        </div>
        <span>公告</span>
      </div>
      <div class="ad-content">
        <ul>
          <li v-for="item in adList" :key="item.id" @click="onToArticle(item)">
            <div class="radio"></div>
            <div class="title">{{ item.title }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="article">
      <div class="header">
        <div class="img">
          <img src="../../../assets/images/article.png" alt="" />
        </div>
        <span>精选文章</span>
      </div>
      <div class="article-content">
        <el-carousel arrow="always" ref="carousel">
          <el-carousel-item v-for="item in articleList" :key="item.id">
            <div class="article-page" @click="onToArticle(item)">
              <img :src="item.converImg" alt="" />
              <div>
                {{ item.title }}
                <div class="time">
                  {{ item.creationTime.split(" ")[0] }}
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <el-dialog :title="article.title" width="50%" v-model="articleDialogVisibility">
      <p v-html="article.content"></p>
      <template #footer>
        <el-button type="primary" @click="articleDialogVisibility = false">关闭</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { nextTick, reactive } from "vue";

import { article, ad } from "@/api/result";
export default {
  setup () {
    const state = reactive({
      adList: [], // 公告列表
      articleList: [], // 文章列表
      carousel: null,// 文章轮播图的 ref 对象
      articleDialogVisibility: false, // 是否显示文章弹框
      article: {} // 文章对象
    });
    article().then(res => {
      state.articleList = res.items
      nextTick(() => state.carousel.setActiveItem(0))
    });
    ad().then(res => state.adList = res.items);
    // 点击查看文章,公告
    state.onToArticle = article => {
      state.articleDialogVisibility = true
      state.article = article
    }
    return state;
  },
};
</script>

<style lang="less" scoped>
.article-ad {
  padding: 10px 0 20px 270px;
  .ad {
    display: inline-block;
    width: 650px;
    height: 221px;
    background: @indexAdBg;
    border-radius: 10px;
    overflow: hidden;
    .header {
      height: 72px;
      background: @indexAdTopBg;
      position: relative;
      .img {
        position: absolute;
        left: 28px;
        top: 50%;
        transform: translateY(-50%);
        height: 51px;
        width: 51px;
        border-radius: 50%;
        display: inline-block;
        background: @indexAdTopImgBg;
        img {
          width: 20px;
          height: 20px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }

      span {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: @indexAdTopTextColor;
        line-height: 72px;
        margin-left: 94px;
      }
    }
    .ad-content {
      height: 149px;
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      padding-left: 98px;
      padding-top: 18px;
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      li {
        cursor: pointer;
        margin-top: 5px;
        color: @indexAdTextColor;
        font-size: 20px;
        font-weight: 400;
        height: 35px;
        border-bottom: 1px dashed @indexTextBorderColor;
        position: relative;

        .radio {
          width: 8px;
          height: 8px;
          background: @indexTextRadioColor;
          border-radius: 50%;
          position: absolute;
          top: 25%;
        }
        .title {
          margin-left: 20px;
        }
      }
    }
  }
  .article {
    display: inline-block;
    width: 650px;
    height: 221px;
    background: @indexArticleBgColor;
    border-radius: 10px;
    margin-left: 60px;
    overflow: hidden;
    .header {
      height: 72px;
      background: @indexArticleTopColor;
      position: relative;
      .img {
        position: absolute;
        left: 28px;
        top: 50%;
        transform: translateY(-50%);
        height: 51px;
        width: 51px;
        border-radius: 50%;
        display: inline-block;
        background: @indexArticleTopIconColor;
        img {
          width: 20px;
          height: 20px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }

      span {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: @indexArticleTopTextColor;
        line-height: 72px;
        margin-left: 94px;
      }
    }
    .article-page {
      cursor: pointer;
      padding-left: 120px;
      padding-right: 53px;
      display: flex;
      padding-top: 20px;
      img {
        width: 125px;
        height: 91px;
        border-radius: 3px;
      }
      > div {
        font-size: 20px;
        font-weight: 400;
        color: @indexArticleTextColor;
        margin-left: 15px;
        .time {
          font-size: 15px;
        }
      }
    }
  }
}
</style>