<template>
  <div class="login-container">
    <div class="login-header">学生端</div>
    <div class="login-content">
      <div class="login-top">
        <img :src="logo" alt />
        <div class="login-border"></div>
        <span>带你走进科技的世界</span>
      </div>
      <div class="login-center">
        <div class="center-user">
          <img src="../../assets/images/user/user.png" alt />
          <input type="text" placeholder="请输入用户名称" v-model="username"/>
        </div>
        <div class="center-user center-pwd">
          <img src="../../assets/images/user/password.png" alt />
          <input type="password" placeholder="请输入密码" v-model="password"/>
        </div>
      </div>
      <div class="login-bottom" @click="onLogin" >立即登陆</div>
    </div>
    <div class="login-info">
        <span class="info1">系统拥有：{{title}}    </span>
        <span class="info2">技术支持： 福建众聚互联  | <a style="color: white" href="http://www.beian.miit.gov.cn">闽ICP备15019871号-3</a></span>
    </div>
  </div>
</template>

<script>
import { publicInfo } from "@/api/tenants";
import {  reactive } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ElMessageBox }  from 'element-plus'
export default {
    setup () {
        const store = useStore()
        const router = useRouter()
        const state = reactive({
            logo: '',
            title: '',
            username: '',
            password: '',
        })
        publicInfo().then(res=>{
            state.logo = res.logo
            document.title = res.name
            state.title = res.name
        })
        state.onLogin = ()=>{
              store
                .dispatch("user/login", {
                    username:state.username,
                    password:state.password
                })
                .then(message => {
                  ElMessageBox({
                        message,
                        type: "success",
                        duration: 1000
                    });
                    router.go(-1)
                })
                .catch(message => {
                  ElMessageBox({
                        message,
                        type: "warning"
                    });
                })
        }

        return state
    }
}
</script>

<style lang="less" scoped>
.login-container {
  width: 100%;
  height: 100%;
  background: url("../../assets/images/user/bg.png");
  background-size: 100% 100%;
  .login-header {
    position: absolute;
    left: 290px;
    top: 0px;
    width: 263px;
    height: 110px;
    background: rgba(255, 144, 14, 1);
    border-radius: 0px 0px 29px 29px;
    text-align: center;
    line-height: 110px;
    color: #ffffff;
    font-size: 70px;
    font-family: zkklt;
  }
  .login-content {
    position: absolute;
    left: 640px;
    top: 300px;
    .login-top {
      width: 641px;
      height: 119px;
      background: rgba(40, 189, 121, 1);
      border-radius: 20px 20px 0 0;
      display: flex;
      align-items: center;
      img {
    
        margin-left: 33px;
        max-height: 66px;
        max-width: 160px;
      }
      .login-border {
        width: 1px;
        height: 45px;
        background: rgba(23, 159, 97, 1);
        margin-left: 30px;
      }
      span {
        font-size: 43px;
        color: #fff;
        font-family: zkklt;
        margin-left: 27px;
      }
    }
    .login-center {
      width: 100%;
      height: 261px;
      background: #fff;
      padding-top: 49px;
      padding-left: 150px;
      .center-user {
        width: 371px;
        height: 58px;
        background: rgba(211, 241, 229, 1);
        border-radius: 8px;
        display: flex;
        align-items: center;
        img {
          width: 27px;
          height: 28px;
          margin-left: 27px;
        }
        input {
          width: 70%;
          margin-left: 27px;
          border: none;
          background: none;
          font-size: 20px;
          color: #333;
          padding-top: 5px;
        }
        input::-webkit-input-placeholder {
          font-size: 16px;
          font-family: regular;
          color: rgba(145, 157, 173, 1);
        }
      }
      .center-pwd {
        margin-top: 35px;
      }
    }
    .login-bottom {
      cursor: pointer;
      width: 100%;
      height: 78px;
      text-align: center;
      background: url("../../assets/images/user/btn.png") no-repeat;
      background-size: 100% 100%;
      line-height: 78px;
      color: #fff;
      font-family: regular;
      font-size: 30px;
      border-radius: 0 0 5px 5px;
    }

  }
  .login-info {
      position: absolute;
      left: 820px;
      bottom: 30px;
      font-size: 16px;
      font-family: regular;
      color: rgba(255, 255, 255, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
    }
}
</style>