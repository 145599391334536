import axios from 'axios'
import {  Message } from 'element-plus'
import store from '@/store'
import { apiUrl, debug, testApiUrl } from '@/settings'
import qs from 'qs'
import { ElLoading  } from 'element-plus'
let loading
let baseURL = debug ? testApiUrl : apiUrl

const service = axios.create({
  baseURL ,
  timeout: 60000 // request timeout
})
// request interceptor
service.interceptors.request.use(

  config => {
    config.baseURL = store.getters.tenantsApi
    // do something before request is sent
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
    if (config.data && !config.json) {
      config.data = qs.stringify(config.data)
    }

    if( config.loading){
      loading = ElLoading.service({ fullscreen: true,background:'rgba(255,255,255,.2)' })
    }
    if (config.json) {
      config.headers['Content-Type'] = 'application/json; charset=utf-8'
      config.data = JSON.stringify(config.data)
    }
    if (config.method.toUpperCase() === 'GET' && config.data) {
      config.url += '?' + config.data
    }
    if (config.files) {
      const formData = new FormData()
      for (const key in config.files) {
        formData.append(key, config.files[key])
      }
      config.headers['Content-Type'] = 'application/json; charset=utf-8'
      config.data = formData
    }
    // 如果有登录了， 并且没有 Authorization,那么就添加请求token
    if (store.getters.token && !config.headers['Authorization']) {
      config.headers['Authorization'] = 'Bearer ' + store.getters.token.access_token
    }
    return config
  },
  error => {
    // do something with request åerror
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    if(loading){
      setTimeout(()=>{
        loading.close()
      },300)
    }
    if (response.status === 200 || response.status === 204) {
      return response.data
    }

    if(response.data.error && response.data.error.message)
    Message({
      message: response.data.error.message,
      type: 'warning',
      duration: 2000
    })
    return Promise.reject(new Error(response.data || 'Error'))

  },
  error => {
      if(loading){
          setTimeout(()=>{
              loading.close()
          },300)
      }
    if (error.response.status === 401) {
       //  if(!localStorage.getItem('reload')) {
       //      Message({
       //          message: '登录过期，请重新登录！',
       //          type: 'error',
       //          duration: 2000
       //      })
       //  }
       //
       //
       // store.dispatch('user/logout')
       // localStorage.setItem('reload',true)
       // router.replace('/login')

      return Promise.reject('登录过期，请重新登录！')
    }

    if(!error.response){
      Message({
        message: '请求发生错误:' + error.message,
        type: 'error',
        duration: 2000
      })
      return Promise.reject(error.message)
    }
    if(error.response.data.error && error.response.data.error.message)
    Message({
      message: error.response.data.error.message,
      type: 'warning',
      duration: 2000
    })
    return Promise.reject(error.response.data)
  }
)

export default service
