<template>
  <div id="index-container">
    <Nav @onShowIndex="onShowIndex" @onShowMatch="onShowMatch" />
    <!-- 轮播图 start-->
    <div class="swiper-box">
      <div class="swiper-container">
        <div class="swiper-wrapper" @click="onToHref" v-if="bannerList.length > 0">
          <div class="swiper-slide" v-for="(item, index) in bannerList" :key="index">
            <img :src="item.url" alt :data-href="item.href" style="cursor: pointer" />
          </div>
        </div>
        <div class="swiper-wrapper" @click="onToHref" v-else>
          <div class="swiper-slide">
            <img :src="themeImg?.indexBg" alt />
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <!-- 页面主体内容 -->
    <div class="content-show" :class="{'content-small':!showResult}" v-show="showIndex">
      <Ad />
      <Result v-if="showResult" />
    </div>
    <Match v-show="showMatch" />
    <Footer :urlList="urlList" />
  </div>
</template>

<script>
import { nextTick, reactive } from "vue";
import Swiper from "swiper";
import Nav from "./components/Nav";
import Ad from './components/Ad'
import Result from './components/Result'
import Footer from './components/Footer'
import Match from './components/Match'
import { getBanners } from "@/api/tenants";
import { useStore } from "vuex";
import { useScroll } from '@/hooks/scroll'

export default {
  name: 'index',
  components: { Nav, Ad, Result, Footer, Match },

  setup () {
    useScroll()
    const state = reactive({
      bannerList: [], // 轮播图列表
      urlList: [], // 友情链接列表
      showIndex: true, // 显示首页
      showMatch: false, // 显示赛事组件
      resultSetting: {},
      themeImg: {},
      showResult:true, // 显示作品
    });
    const store = useStore();
    const siteInfo = store.getters.tenantInfo;
    state.showResult = Number(siteInfo.tenantType) == 3 ? false : true
    // let curImgList = computed(() => {
    //   console.log('计算生效')
    //   return store.getters.curThemeImg;
    // })
    // state.themeImg = watch(curImgList, val => {
    //   console.log('监听生效')
    //   return state.themeImg = val
    // })
    state.themeImg = store.getters.curThemeImg;
    console.log(store.getters.curThemeImg, 'state.themeImg')
    state.logo = siteInfo.logo;
    state.siteInfo = siteInfo;

    // 获取轮播图
    getBanners().then((res) => {
      state.bannerList = res.portalBanners;
      state.urlList = res.friendshopLinks;
      nextTick(() => {
        new Swiper(".swiper-container", {
          autoplay: true,
          loop: true,
          autoHeight: true,
        });
      });
    });
    // 显示主页
    state.onShowIndex = () => {
      state.showIndex = true
      state.showMatch = false
    }
    // 显示赛事页面
    state.onShowMatch = () => {
      state.showMatch = true
      state.showIndex = false
    }

    return state
  },
};
</script>

<style lang="less" scoped>
#index-container {
  .swiper-box {
    width: 100%;
    background: @baseColor;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content-show {
    background: @baseColor;
    // padding-bottom: 105px;
    padding-top: 20px;
    &.content-small{
      padding-bottom: 20px;
    }
  }
}
</style>