import request from '@/utils/request'
import { tenantDomain } from '@/settings'
// 获取列表
export function tenantsList () {
  return request({
    url: `https://api.mamale.vip/api/tenants/public`,
    method: 'GET',
    data: { PageIndex: 1, PageSize: 10000 },
  })
}
/**
 *  公共的，获取信息
 */
export function publicInfo () {
  let domain = location.hostname
  if (domain === 'localhost') {
    domain = tenantDomain + '.mamale.vip'
  }
  return request({
    url: `https://api.mamale.vip/api/tenants/domain?domain=${domain}`
  })
}
/**
 * 获取租户信息
 */
export function domain () {
  let hostname = location.hostname
  if (location.hostname === 'localhost') {
    hostname = tenantDomain + '.mamale.vip'
  }
  return request({
    url: 'https://api.mamale.vip/api/tenants/domain?domain=' + hostname,
    method: 'GET',
    loading: true
  })
}


export function getBanners () {
  return request({
    url: '/api/tenantSetting/public',
    method: 'GET'
  })
}

/**
 *  获取当前服务器时间
 */
export function pCurrentDateTime () {
  return request({
    url: `https://api.mamale.vip/api/app/exam/pCurrentDateTime`
  })
}

/**
 *  获取学生端版本信息
 */
export function studentVersionInfo () {
  return request({
    url: '/api/tenantSetting/publicStudent',
  })
}
