<template>
  <div class="resources-container" id="courseDetail">
    <ReturnBtn @getReturnUrl="onReturn" />
    <div class="container-contents">
      <div class="plane">
        <div class="tree">
          <tree @clickMenu="choseMenu"></tree>
        </div>
        <div class="list">
          <list ref="list"></list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReturnBtn from "@/components/ReturnBtn";
import Tree from './components/Tree'
import List from './components/List'

export default {
  components: { ReturnBtn, Tree, List },
  methods: {
    onReturn () {
      this.$router.back()
    },
    choseMenu (data) {
      this.$refs.list.search({
        fileLibCategoryId: data
      })
    }
  },
  setup () {

    return {}
  }
}
</script>

<style lang="less" scoped>
.resources-container {
  background: url("../../assets/images/courses/index-bg.png") no-repeat;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  position: fixed;
  overflow-y: scroll;

  .container-return {
    cursor: pointer;
    width: 58px;
    position: absolute;
    left: 44px;
    top: 59px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .container-contents {
    position: absolute;
    left: 358px;
    top: 124px;
    width: 1200px;
    height: 745px;
    min-height: 745px;
    background: rgba(255, 255, 255, 1);
    border-radius: 30px;
    padding: 58px 0;

    .plane {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
    }

    .tree {
      padding-left: 10px;
      width: 240px;
      height: 100%;
      overflow-y: scroll;
    }

    .list {
      padding-left: 18px;
      width: 80%;
      height: 100%;
    }
  }
}
</style>