<template>
  <div class="container-401">
    <div class="content">
      <img src="./code.jpg" alt />
      <div>
        <p>机构已过期,请联系管理员</p>
        <p>17758713709</p>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex"
import { useRouter } from 'vue-router'
export default {
  setup () {
    const store = useStore()
    const endTime = store.getters.tenantInfo.endTime
    if (new Date(endTime).getTime() > new Date().getTime()) {
      const router = useRouter()
      router.push({
        name: 'index'
      })
    }
  },
};
</script>

<style scoped lang="less">
.container-401 {
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, #d3959b, #bfe6ba);
  background: linear-gradient(to right, #c9d1de, #7180f7);
}
.content {
  height: 720px; /*no*/
  width: 1070px; /*no*/
  background: #2c3bae;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  box-shadow: 7px 0px 40px 0px #7180f7;
  img {
    display: block;
    margin: 0 auto 0;
    width: 280px; /*no*/
    height: 280px; /*no*/
    border: 1px solid #cccccc;
  }
  p {
    margin-top: 30px;
    margin-bottom: 0;
    color: #ef942b;
    font-size: 76px;
    font-weight: bold;
    text-align: center;
    line-height: 76px;
  }
  p:first-child {
    font-size: 56px;
  }
}
</style>
