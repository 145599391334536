<template>
  <el-tree class="filter-tree" :data="catalogList" :props="defaultProps" default-expand-all
   :current-node-key="currenKey"
   :highlight-current="true"
    @node-click="handleNodeClick" :filter-node-method="filterNode" :expand-on-click-node="false">
  </el-tree>
</template>

<script>

import { publicList } from '@/api/download'
import { reactive } from 'vue';

export default {
  name: "tree",
  setup (props, context) {
    const state = reactive({
      catalogList: [], // 默认的分类记录列表
      defaultProps: {  // 默认的字节点 key 跟展示的名称的key
        children: 'children',
        label: 'name'
      },
      currenKey: 'parent' // 默认选中的节点
    })
    // 对树形结构的数据进行处理
    const handleGetTreeData = (data, parentId) => {
      const list = []
      data.forEach(item => {
        if (item.parentId == parentId) {
          item.children = handleGetTreeData(data, item.id)
          list.push(item)
        }
      })
      return list
    }
    // 初始化数据
    publicList().then(res => {
      state.catalogList =
        [
          {
            name: '分类',
            id: 'parent',
            children: handleGetTreeData(res.items)
          }
        ]
    })
    // 对节点过滤的方法
    state.filterNode = (value, data) => {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    }
    // 当点击节点时触发
    state.handleNodeClick = data => {
      context.emit('clickMenu', data.id)
    }
    return state
  }
}
</script>

<style scoped>
</style>
