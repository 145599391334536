<template>
  <div class="border"></div>
  <div id="footer">
    <div class="bottom-left">
      <span>联系我们</span>
      <span>
        地址：{{ siteInfo.province }}{{ siteInfo.city }}{{ siteInfo.area
        }}{{ siteInfo.addr }}
      </span>
      <span>电话：{{ siteInfo.managerTel }}</span>
    </div>
    <div class="bottoms">
      <span class="bottoms-title">友情链接</span>
      <div class="url">
        <a
          class="spans"
          target="_blank"
          :href="item.url"
          v-for="(item, index) in urlList"
          :key="index"
        >{{ item.name }}</a>
      </div>
    </div>
    <div class="bottom-left bottom-right">
      <span>备案号</span>
      <span>
        <a
          class="link"
          href="https://beian.miit.gov.cn/"
          target="_blank"
        >{{ siteInfo.recordNumber }}</a>
      </span>
    </div>
    <el-image class="bottom-qrcode" :src="siteInfo.qrcode" :preview-src-list="[siteInfo.qrcode]" />
  </div>
</template>

<script>
import { useStore } from 'vuex'

export default {
  props: ['urlList'],
  setup () {
    const siteInfo = useStore().getters.tenantInfo
    return {
      siteInfo
    }
  }
}
</script>

<style lang="less" scoped>
.border {
  width: 100%;
  height: 2px;
  background-color: @footerBorder;
}
#footer {
  width: 100%;
  min-height: 247px;
  padding-bottom: 20px;
  background: @footColor;
  display: flex;

  .bottom-left {
    padding: 64px 0 0 401px;
    display: flex;
    flex-direction: column;

    span:first-child {
      font-size: 20px;
      font-family: bold;
      color: @footerText;
    }

    span:nth-child(2) {
      font-size: 14px;
      color: @footerText;
      margin-top: 27px;
    }

    span:nth-child(3) {
      font-size: 14px;
      color: @footerText;
      margin-top: 10px;
    }

    span:nth-child(4) {
      font-size: 14px;
      color: @footerText;
      margin-top: 10px;
    }
  }

  .bottom-right {
    padding: 64px 0 0 200px;
  }

  .bottom-center {
    padding: 64px 0 0 105px;
  }

  .bottoms {
    display: flex;
    flex-direction: column;
    padding: 64px 0 0 100px;
    width: 20%;

    .bottoms-title {
      font-size: 20px;
      font-family: bold;
      color: @footerText;
    }

    .url {
      font-size: 14px;
      color: @footerText;
      margin-top: 22px;
      display: flex;
      flex-direction: column;

      .spans {
        font-size: 14px;
        color: @footerText;
        margin-top: 5px;
        word-break: break-all;
        width: 100%;
      }
    }
  }

  .bottom-qrcode {
    margin-top: 44px;
    margin-left: 122px;
    width: 175px;
    height: 175px;
  }
  .link {
    color: @footerText;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>