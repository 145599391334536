<template>
  <div class="result" v-if="isPlatOpenResult && isSeeCommunityResult">
    <div class="show-nav">
      <div
        :class="showActive && !isGood ? 'show-title show-active' : 'show-title'"
        @click="OnSelectResult(true, false)"
      >
        <div :class="showActive ? 'title-left title-active' : 'title-left'"></div>
        <span>{{ tenantType }}最新</span>
        <div :class="showActive ? 'title-right title-active' : 'title-right'"></div>
      </div>
      <div
        :class="showActive && isGood ? 'show-title show-active' : 'show-title'"
        @click="OnSelectResult(true, true)"
      >
        <div :class="showActive ? 'title-left title-active' : 'title-left'"></div>
        <span>{{ tenantType }}精选</span>
        <div :class="showActive ? 'title-right title-active' : 'title-right'"></div>
      </div>
        <div
          :class="
            !showActive && !isGood ? 'show-title1 show-active1' : 'show-title1'
          "
          @click="OnSelectResult(false, false)"
        >
          <div :class="showActive ? 'title-left' : 'title-left title-active'"></div>
          <span>社区最新</span>
          <div :class="showActive ? 'title-right' : 'title-right title-active'"></div>
        </div>
        <div
          :class="
            !showActive && isGood ? 'show-title1 show-active1' : 'show-title1'
          "
          @click="OnSelectResult(false, true)"
        >
          <div :class="showActive ? 'title-left' : 'title-left title-active'"></div>
          <span>社区精选</span>
          <div :class="showActive ? 'title-right' : 'title-right title-active'"></div>
        </div>
    </div>
    <div class="show-search" v-if="!showActive && showIndex">
      <div
        class="search-area"
        @mouseenter="showInstitution = false"
        @mouseleave="showInstitution = false"
      >
        <div>搜索</div>
        <!--          <img src="../../../assets/images/xiala2.png" alt />-->
      </div>
      <div class="search-content">
        <input
          type="text"
          placeholder="请搜索作品名称"
          id="search-input"
          v-model="searchInput"
          autocomplete="off"
        />
        <img
          :src="currentTheme?.sousuo"
          alt
          id="search-icon"
          style="cursor: pointer"
          @click="onSearchResult(false)"
        />
      </div>
      <div
        class="show-list"
        v-if="showInstitution"
        @mouseenter="showInstitution = true"
        @mouseleave="showInstitution = false"
      >
        <div
          class="list-item"
          @click="onSearchResult(item)"
          :class="{ 'list-active': activeId === item.id }"
          v-for="item in tenantsList"
          :key="item.id"
        >
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>

    <!--            本机构-->
    <div v-show="showActive" class="show-center">
      <div
        class="center-item"
        v-for="item in resultList"
        :key="item.id"
        @click="$router.push('/detail?workerId=' + item.id)"
      >
        <img :src="item.photo" alt class="center-top" />
        <span class="center-center">{{ item.name }}</span>
        <div class="item-bottom">
          <div class="bottom-left">
            <div class="left-border">
              <img src="../../../assets/images/show-img.png" alt />
            </div>
            <span v-if="item.student">{{ item.student.name }}</span>
          </div>
          <div class="bottom-right">
            <img src="../../../assets/images/see.png" alt />
            <span>{{ item.view }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="center-bottom"
      @click="getResultList"
      v-show="(totalCount > resultList.length || totalCount == 0) && showActive"
    >
      <span>MORE+</span>
      <span>查看更多</span>
    </div>
    <!--            总机构-->
    <div class="show-center" v-show="!showActive">
      <div
        class="center-item"
        v-for="item in resultList2"
        :key="item.id"
        @click="$router.push('/detail?workerId=' + item.id + '&isCommon=true')"
      >
        <img :src="item.photo" alt class="center-top" />
        <span class="center-center">{{ item.name }}</span>
        <div class="item-bottom">
          <div class="bottom-left">
            <div class="left-border">
              <img src="../../../assets/images/show-img.png" alt />
            </div>
            <span v-if="item.student">{{ item.student.name }}</span>
          </div>
          <div class="bottom-right">
            <img src="../../../assets/images/see.png" alt />
            <span>{{ item.view }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="center-bottom"
      @click="getResultList2"
      v-show="(totalCount2 > resultList2.length || totalCount2 == 0) && !showActive"
    >
      <span>MORE+</span>
      <span>查看更多</span>
    </div>
  </div>
</template>

<script>
import { reactive, computed, toRefs } from "vue"
import { useStore } from "vuex"
import { useGetResult } from "./logic"
import { tenantsList } from "@/api/tenants"

export default {
  setup () {
    const state = reactive({
      matchVisiblity: false, // 是否显示赛事
      matchResultVisiblity: false, //赛事作品
      matchList: [], // 赛事列表
      siteInfo: {},
      tenantTypeOption: {
        0: '机构端',
        1: '学校端',
        2: '主办方',
        3: '机构端'
      },
      teacherTypeOption: {
        0: '教师端',
        1: '教师端',
        2: '教师端',
        3: '教师端'
      },
      showActive: true, //判断作品点击状态
      showIndex: true, // 显示首页内容,
      showInstitution: false, //机构下拉列表的状态
      isGood: false,//作品是否精选,
      activeId: "", // 默认选择的机构索引
    })
    const store = useStore()
    const siteInfo = store.getters.tenantInfo
    state.tenantType = state.tenantTypeOption[Number(siteInfo.tenantType)] || '机构端'

    tenantsList().then(res => {
      let list = res.items
      let saveList = [{ name: "全部机构", id: "0" }]
      for (let i = 0; i < list.length; i++) {
        if (list[i].sassTwoDomainSign) {
          list[i].tenantsApi =
            "https://" +
            list[i].sassTwoDomainSign.trim() +
            "-api" +
            list[i].sassTwoDomain.replace(
              list[i].sassTwoDomain.split(".")[0],
              ""
            ) +
            "/"
        }
        saveList.push(list[i])
      }
      state.tenantsList = saveList
      state.activeItem = saveList[0]
      state.activeId = saveList[0].id
    })



    
    // 查询作品
    state.onSearchResult = (item) => {
      if (item) {
        state.showInstitution = false
        state.activeId = item.id
        state.activeItem = item
        state.searchInput = ""
        // return
      }
      state.page2 = 0
      state.resultList2 = []
      state.totalCount2 = 5
      state.searchName = state.searchInput
      state.getResultList2()
    }
    const currentTheme = computed(() => store.getters.curThemeImg)
    console.log('当前主题：', currentTheme)
    //作品导航栏切换
    useGetResult(state)

    return {
      currentTheme,
      ...toRefs(state)
    }
  },
}
</script>

<style lang="less" scoped>
.result {
    padding-bottom: 105px;

  .show-nav {
    display: flex;
    width: 100%;
    justify-content: center;

    .show-title {
      cursor: pointer;
      user-select: none;
      width: 310px;
      height: 77px;
      border: 3px solid @indexResultTabbarLeftBorderColor;
      border-radius: 10px;
      text-align: center;
      line-height: 77px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 30px;

      .title-left {
        width: 18px;
        height: 18px;
        background: @indexResultTabbarLeftCircleColor;
        border-radius: 50%;
        margin-left: 31px;
      }

      span {
        font-size: 28px;
        color: @indexResultTabbarLeftTextColor;
      }

      .title-right {
        width: 18px;
        height: 18px;
        background: @indexResultTabbarLeftCircleColor;
        border-radius: 50%;
        margin-right: 31px;
      }
    }

    .show-active {
      cursor: pointer;
      user-select: none;
      background-color: @indexResultTabbarLeftBgColorOfActive;
      border: none;
      .title-active {
        background: @indexResultTabbarLeftCircleColorOfActive;
      }
      span {
        color: @indexResultTabbarLeftTextColorOfActive;
      }
    }

    .show-title1 {
      cursor: pointer;
      user-select: none;
      width: 310px;
      height: 77px;
      border: 3px solid @indexResultTabbarRightBorderColor;
      border-radius: 10px;
      text-align: center;
      line-height: 77px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 30px;

      .title-left {
        width: 18px;
        height: 18px;
        background: @indexResultTabbarRightCircleColor;
        border-radius: 50%;
        margin-left: 31px;
      }

      span {
        font-size: 28px;
        color: @indexResultTabbarRightTextColor;
      }

      .title-right {
        width: 18px;
        height: 18px;
        background: @indexResultTabbarRightCircleColor;
        border-radius: 50%;
        margin-right: 31px;
      }
    }

    .show-active1 {
      background-color: @indexResultTabbarRightBgColorOfActive;

      .title-active {
        background: @indexResultTabbarRightCircleColorOfActive;
      }
      span {
        color: @indexResultTabbarRightTextColorOfActive;
      }
    }
  }

  .show-search {
    display: flex;
    height: 94px;
    margin-top: 44px;
    position: relative;
    margin-left: 360px;

    .search-area {
      cursor: pointer;
      user-select: none;
      width: 233px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: @indexResultTabbarSearchTitleBgColor;
      border-radius: 10px 0px 0px 10px;
      font-size: 30px;
      color: @indexResultTabbarSearchTitleTextColor;

      img {
        width: 27px;
        height: 25px;
        margin-left: 16px;
      }
    }

    .search-content {
      width: 967px;
      height: 100%;
      display: flex;
      align-items: center;
      background-color: @indexResultTabbarSearchContentBgColor;
      justify-content: space-between;

      input {
        border: none;
        background: none;
        width: 85%;
        margin-left: 35px;
        font-size: 26px;
      }

      img {
        width: 40px;
        height: 40px;
        margin-right: 40px;
      }
    }
  }

  .show-list {
    cursor: pointer;
    user-select: none;
    width: 233px;
    max-height: 256px;
    background: @indexResultTabbarSearchFilterBgColor;
    border-radius: 0px 0px 10px 10px;
    position: absolute;
    overflow: scroll;
    left: 0;
    top: 94px;
    padding-top: 5px;
    padding-bottom: 5px;

    .list-item {
      width: 233px;
      height: 53px;
      padding-left: 12px;
      display: flex;
      align-items: center;
      font-size: 26px;
      color: @indexResultTabbarSearchFilterTextColor;
      border-bottom: 1px dashed @indexResultTabbarSearchFilterBorderColor;

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .list-active {
      color: @indexResultTabbarSearchFilterTextColorByActive;
    }

    .list-item:last-child {
      border: none;
    }
  }

  .show-list::-webkit-scrollbar {
    display: none;
  }

  .show-center {
    display: flex;
    flex-wrap: wrap;
    max-width: 1250px;
    margin-left: 320px;
    /*margin-top: 23px);*/
    .center-item {
      cursor: pointer;
      width: 209px;
      height: 255px;
      background: @indexResulItemBgColor;
      margin-left: 40px;
      margin-top: 50px;
      border: 1px solid @indexResulItemBorderColor;
      // opacity: 0.1;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .center-top {
        width: 100%;
        height: 140px;
        border-radius: 10px 10px 0 0;
      }

      .center-center {
        font-size: 20px;
        color: @indexResultItemInfoTextColor;
        margin-left: 11px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .item-bottom {
        margin-left: 10px;
        margin-bottom: 10px;
        display: flex;
        height: 30px;
        align-items: center;
        justify-content: space-between;

        .bottom-left {
          display: flex;
          height: 100%;
          align-items: center;

          .left-border {
            width: 31px;
            height: 30px;
            background: @indexResultItemInfoUserImgBgColor;
            border-radius: 50%;
            padding-left: 2.1px;
            padding-top: 3px;

            img {
              width: 26px;
              height: 25px;
              border-radius: 50%;
            }
          }

          span {
            margin-left: 12px;
            font-size: 16px;
            color: @indexResultItemInfoUserTextColor;
            width: 80px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .bottom-right {
          padding-top: 5px;
          display: flex;
          margin-right: 10px;

          img {
            width: 20px;
            height: 15px;
          }

          span {
            margin-left: 10px;
            font-size: 16px;
            color: @indexResultItemInfoSeeNumTextColor;
          }
        }
      }
    }
  }

  .center-bottom {
    cursor: pointer;
    width: 161px;
    height: 55px;
    border: 1px solid @indexResultBtnMainColor;
    border-radius: 5px;
    text-align: center;
    line-height: 55px;
    position: relative;
    margin-left: 879px;
    margin-top: 76px;

    span:first-child {
      font-size: 20px;
      font-family: bold;
      color: @indexResultBtnMainColor;
      position: absolute;
      left: 44px;
      top: 0px;
    }

    span:nth-child(2) {
      font-size: 28px;
      font-family: bold;
      color: @indexResultBtnTextColor;
      opacity: 0.05;
    }
  }
}
#search-input {
  color: @indexResultTabbarSearchContentTextColor;
  outline: medium;
  &::placeholder {
    color: @indexResultTabbarSearchContentTipsColor;
  }
}
</style>