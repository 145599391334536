const arr = [
    {
        // rect: require("@/assets/images/courses/rect.png"),
        sousuo: require("@/assets/images/sousuo.png"),
        // returnback: require("@/assets/images/courses/return.png"),
        // see: require("@/assets/images/see.png"),
        indexBg: require("@/assets/images/index-bg.png"),
    }, {
        sousuo: require("@/assets/images/sousuo.png"),
        indexBg: require("@/assets/blue/index-bg.jpg"),
    }]

export default arr





